import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { getProductImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import { mediaShape } from 'modules/media/propTypes';
import classes from './ProductThumbnail.module.scss';
import Button from '../ui/Button/Button';
import findObjectById from '../../utils/findObjectById';

const ProductThumbnail = ({
  className,
  name,
  media,
  price,
  isLiked,
  showStaticOverlay,
  staticOverlay,
  hoverOverlay,
  productImgUrl,
  onClick,
  handleToggleLikeProduct,
  onViewLookBoard,
}) => {
  const [hover, setHover] = useState(false);

  const imgUrl = useMemo(
    () => buildResourceUrl(productImgUrl.medium, media?.userId, media?.hash),
    [media, productImgUrl]
  );

  const handleHover = useCallback(() => {
    setHover(true);
  }, []);

  const handleBlur = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div
      className={clsx(classes.root, className)}
      onMouseEnter={hoverOverlay ? handleHover : null}
      onMouseLeave={hoverOverlay ? handleBlur : null}
    >
      <div className={clsx('flex-fill flex-center', classes.imageWrapper)}>
        <img
          alt={name}
          draggable="false"
          src={imgUrl}
          // className={clsx({ [classes.blurred]: hover })}
          onClick={onClick || null}
        />
      </div>
      <div className={clsx('d-flex flex-column', classes.bottomBar)}>
        <div
          className={clsx(
            'd-flex justify-content-between align-items-center',
            classes.productLabel
          )}
        >
          <div className="py-1">
            <p
              title={name}
              className={`${classes.productName} text-ellipsis text-sm text-gray font-semi-bold mb-1`}
            >
              {name}
            </p>
            <span className="primary-color text-sm font-semi-bold">
              {price ? `$${price}` : 'N/A'}
            </span>
          </div>
          <Button variant="contained" inline size="xs" onClick={onClick}>
            <span className="px-2">VIEW</span>
          </Button>
        </div>
        <div className="d-flex justify-content-between align-items-center py-1">
          <div>
            {onViewLookBoard && (
              <Button
                variant="outlined"
                inline
                size="xs"
                className={classes.lookBoardsBtn}
                onClick={onViewLookBoard}
              >
                <span className="px-2">View in Look Boards</span>
              </Button>
            )}
          </div>

          <IconButton
            size="sm"
            variant="inverted-grey"
            onClick={handleToggleLikeProduct}
          >
            <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="sm" />
          </IconButton>
        </div>
      </div>
      {showStaticOverlay && (
        <div
          className={clsx(classes.staticOverlay, { [classes.hidden]: hover })}
        >
          {staticOverlay}
        </div>
      )}
      <div className={clsx(classes.overlay, { [classes.hidden]: !hover })}>
        {hoverOverlay}
      </div>
    </div>
  );
};

ProductThumbnail.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  price: PropTypes.number,
  isLiked: PropTypes.number.isRequired,
  media: mediaShape.isRequired,
  hoverOverlay: PropTypes.node,
  showStaticOverlay: PropTypes.bool,
  onClick: PropTypes.func,
  handleToggleLikeProduct: PropTypes.func,
  onViewLookBoard: PropTypes.func,
  staticOverlay: PropTypes.node,
  productImgUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
};

ProductThumbnail.defaultProps = {
  className: '',
  showStaticOverlay: false,
  staticOverlay: null,
  price: null,
  hoverOverlay: null,
  onClick: () => null,
  onViewLookBoard: null,
  handleToggleLikeProduct: () => null,
};

const mapStateToProps = (
  {
    app: {
      config,
      enums: { itemClasses },
    },
  },
  { itemClassId }
) => ({
  productImgUrl: getProductImgUrl(config),
  itemClass: findObjectById(itemClassId, itemClasses)?.name,
});

export default connect(mapStateToProps)(memo(ProductThumbnail));
