import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import SelectedImageBlock from 'modules/curateTheLook/createLookBoard/components/SelectedImageBlock/SelectedImageBlock';
import { routesByName } from 'constants/routes';
import {
  changeActiveTabAction,
  syncInspirationImageAction,
  toggleLikeLookBoardAction,
  toggleLikeProductAction,
  updateMixMatchSortAction,
  updateMixMatchTypeAction,
  voteLookBoardAction,
} from 'modules/getTheLook/store/actions';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import errorToastr from 'libs/toastr/errorToastr';
import {
  getTheLookTabKeys,
  productsSortKeys,
} from 'modules/getTheLook/constants';
import transformArrayToMap from 'utils/transformArrayToMap';
import NoLookBoardsModal from 'modules/getTheLook/components/NoLookBoardsModal/NoLookBoardsModal';
import { selectImageAction as selectImageCTL } from 'modules/curateTheLook/store/actions';
import { selectImageAction as selectImageRTL } from 'modules/requestTheLook/store/actions';
import LookBoardItem from 'modules/getTheLook/components/LookBoardItem';
import customEvent from 'utils/customEvent';
import {
  SET_INITIAL_SCROLLER_HEIGHT,
  UPDATE_SCROLLER_HEIGHT,
} from 'constants/customEventNames';
import ProductsStream from 'modules/getTheLook/components/ProductsStream';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import MixAndMatchView from 'modules/getTheLook/components/MixAndMatchView';
import mixAndMatchService from 'modules/mixAndMatch/mixAndMatchService';
import successToastr from 'libs/toastr/successToastr';
import classes from 'modules/getTheLook/GetTheLook.module.scss';
import clsx from 'clsx';
import { maxWidthMd } from 'constants/mediaQueries';
import useMediaQuery from '../../hooks/useMediaQuery';
import lookBoardService from '../lookBoard/lookBoardService';
import productService from '../product/productService';
import SelectedLookBoardBlock from './components/SelectedLookBoardBlock';
import LookBoardPreviewModal from '../../components/modals/LookBoardPreviewModal/LookBoardPreviewModal';
import IconButton from '../../components/ui/IconButton/IconButton';
import SpriteIcon from '../../components/ui/SpriteIcon';
import Button from '../../components/ui/Button/Button';

const DetailsView = ({
  match: { params },
  currentImage,
  activeTab,
  lookBoards,
  products,
  users,
  searchParams,
  inspirationImageUrl,
  syncInspirationImage,
  toggleLikeLookBoard,
  voteLookBoard,
  toggleLikeProduct,
  createLookBoard,
  createRequest,
  updateMixMatchType,
  authenticated,
  isLookBoard,
  lookBoardsData,
  changeActiveTab,
}) => {
  const { imageId } = params;
  const history = useHistory();
  const { pathname } = useLocation();
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [currentLookBoard, setCurrentLookBoard] = useState(null);
  const [lookBoardProducts, setLookBoardProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [similarProductGroups, setSimilarProductGroups] = useState({});
  const [lookBoardForModal, setLookBoardForModal] = useState(null);
  const dispatch = useDispatch();
  const mainContainerRef = useRef(null);

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const imgPreviewUrl = useMemo(() => {
    if (!currentImage) {
      return null;
    }

    const {
      media: { userId, hash },
    } = currentImage;

    return buildResourceUrl(inspirationImageUrl.medium, userId, hash);
  }, [currentImage, inspirationImageUrl]);

  const isMixMatchPage = useMemo(
    () =>
      history.location.pathname.includes(
        routesByName.getTheLook.mixMatch.index
      ),
    [history.location.pathname]
  );

  useEffect(() => {
    return () => {
      dispatch(updateMixMatchSortAction(productsSortKeys.bestMatch));
    };
  }, [dispatch]);

  useEffect(() => {
    if (isLookBoard && !loading) {
      setSimilarProductGroups(
        transformArrayToMap(
          lookBoardsData[currentLookBoard.inspirationId]?.products
        )
      );
    }
    // eslint-disable-next-line
  }, [currentLookBoard, isLookBoard, loading, lookBoardsData]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { result: lookBoard } = await lookBoardService.getLookBoardById(
          imageId
        );
        const productsArr = await productService.getProductsByIds(
          lookBoard.products
        );

        const productsMap = transformArrayToMap(productsArr);
        const preparedProductList = lookBoard.products.map(
          (id) => productsMap[id]
        );

        if (lookBoard.inspirationId) {
          await syncInspirationImage(lookBoard.inspirationId);
          // setSimilarProductGroups(transformArrayToMap(lookBoardsData[lookBoard.inspirationId]?.products));
        }
        setCurrentLookBoard(lookBoard);
        setLookBoardProducts(preparedProductList);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    };
    if (isLookBoard) {
      setLoading(true);
      getData()
        .then(() => setLoading(false))
        .catch((e) => {
          errorToastr('Error', e.message);
        });
    }
  }, [imageId, isLookBoard, syncInspirationImage]);

  useEffect(() => {
    (async () => {
      if (!isLookBoard) {
        try {
          await syncInspirationImage(imageId);
        } catch (e) {
          errorToastr('Error', e.message);
        }
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [imageId]);

  useEffect(() => {
    setTimeout(() => {
      customEvent.trigger(SET_INITIAL_SCROLLER_HEIGHT);
      customEvent.trigger(UPDATE_SCROLLER_HEIGHT);
    }, 0);
  }, [lookBoards, activeTab]);

  const goBack = useCallback(() => {
    if (isMixMatchPage) {
      history.push(routesByName.getTheLook.mixMatch.index);
      updateMixMatchType('curated');
      dispatch(updateMixMatchSortAction(productsSortKeys.bestMatch));
      return;
    }
    history.goBack();
  }, [dispatch, history, isMixMatchPage, updateMixMatchType]);

  const handleCreateLookBoard = useCallback(() => {
    createLookBoard(imageId);
    history.push(routesByName.curateTheLook.index);
  }, [createLookBoard, imageId, history]);

  const handleCreateRequest = useCallback(() => {
    createRequest(imageId);
    history.push(routesByName.requestTheLook.index);
  }, [createRequest, imageId, history]);

  const handleToggleLikeLookBoard = useCallback(
    async (lookBoardId, likeStatus) => {
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      try {
        await toggleLikeLookBoard(imageId, lookBoardId, likeStatus);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [authenticated, history, pathname, toggleLikeLookBoard, imageId]
  );

  const handleVoteLookBoard = useCallback(
    async (lookBoardId, voteValue) => {
      try {
        await voteLookBoard(imageId, lookBoardId, voteValue);
      } catch (e) {
        history.push(`?${routesByName.auth.key}=${routesByName.auth.signIn}`);
      }
    },
    [history, voteLookBoard, imageId]
  );

  const handleToggleLikeProduct = useCallback(
    async (productId, likeStatus) => {
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      try {
        await toggleLikeProduct(imageId, productId, likeStatus);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [authenticated, history, pathname, toggleLikeProduct, imageId]
  );

  const handleImgModalOpen = useCallback(() => {
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const handleSelectImage = useCallback(
    (imgId, imgSlug) => {
      if (isMixMatchPage) {
        history.push(routesByName.getTheLook.mixMatch.details(imgId));
        updateMixMatchType('curated');
        return;
      }
      history.push(routesByName.getTheLook.details(imgId, imgSlug));
    },
    [history, isMixMatchPage, updateMixMatchType]
  );

  const handleSelectLookBoard = useCallback(
    (similarId) => {
      history.push(`${pathname.replace(/\d/g, '')}${similarId}`);
    },
    [history, pathname]
  );

  const handleSaveMixAndMatch = useCallback(
    async (productIds) => {
      try {
        await mixAndMatchService.saveMixAndMatch(imageId, productIds);
        successToastr('Success', 'Your Mix & Match successfully saved');
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [imageId]
  );

  const showLookBoardView = useMemo(() => {
    if (activeTab === getTheLookTabKeys.lookBoardView) return true;
    return currentImage && activeTab === getTheLookTabKeys.galleryView;
  }, [activeTab, currentImage]);

  const closeLookBoardForModal = useCallback(() => {
    setLookBoardForModal(null);
  }, []);

  const onOpenLookBoardModal = useCallback(
    (id) => {
      const lookBoard = lookBoards.find((obj) => obj.id === id);
      const productsModal = lookBoard.products.map((item) => products[item]);
      setLookBoardForModal({ ...lookBoard, products: productsModal });
    },
    [lookBoards, products]
  );
  const handleViewProducts = useCallback(() => {
    history.push(routesByName.getTheLook.details(imageId, currentImage.slug));
    changeActiveTab(getTheLookTabKeys.productStream);
  }, [changeActiveTab, currentImage, history, imageId]);

  const handleViewLookBoard = useCallback(() => {
    history.push(routesByName.getTheLook.details(imageId, currentImage.slug));
    changeActiveTab(getTheLookTabKeys.lookBoardView);
  }, [changeActiveTab, currentImage, history, imageId]);

  return (
    <>
      <div
        className={clsx('flex-fill d-flex', {
          'overflow-hidden': matchesMediaQuery,
          'flex-column': matchesMediaQuery,
        })}
      >
        <div
          className={`${classes.leftPanelWrapper} pb-3`}
          style={{
            marginTop: '-110px',
          }}
        >
          {isLookBoard && !loading && (
            <SelectedLookBoardBlock
              inspirationImageId={Number.parseInt(
                currentLookBoard.inspirationId,
                10
              )}
              searchParams={searchParams}
              onBack={goBack}
              onOpenPreview={handleImgModalOpen}
              lookBoard={currentLookBoard}
              lookBoardProducts={lookBoardProducts}
              mainContainerRef={mainContainerRef}
              handleSelectLookBoard={handleSelectLookBoard}
            />
          )}
          {!isLookBoard && currentImage && (
            <SelectedImageBlock
              inspirationImageId={Number.parseInt(imageId, 10)}
              searchParams={searchParams}
              onBack={goBack}
              onOpenPreview={handleImgModalOpen}
              onSelectImage={handleSelectImage}
              showOverlay
            />
          )}
        </div>
        <div className="flex-fill">
          <div className={classes.mainContainer} ref={mainContainerRef}>
            {matchesMediaQuery && (
              <>
                <div className="d-flex align-items-center mb-3">
                  <IconButton
                    variant="inverted-white"
                    size="sm"
                    onClick={goBack}
                  >
                    <SpriteIcon name="left-arrow" size="sm" />
                  </IconButton>
                  <span className="text-sm ml-1">Back</span>
                </div>
                <div className={classes.mobileTitle}>
                  Look Boards in Selected Style
                </div>
                <div className={classes.mobileDescr}>
                  Scroll Down to View All
                </div>
              </>
            )}
            {!lookBoards ||
              (isLookBoard && (
                <div
                  className="text-center"
                  style={{ paddingTop: isMixMatchPage ? 120 : 40 }}
                >
                  Loading...
                </div>
              ))}
            {lookBoards && lookBoards.length === 0 && (
              <div
                className="mt-5 pb-3"
                style={{ paddingTop: isMixMatchPage ? 80 : 40 }}
              >
                <NoLookBoardsModal
                  onCreateLookBoard={handleCreateLookBoard}
                  onCreateRequest={handleCreateRequest}
                />
              </div>
            )}
            {lookBoards && lookBoards.length > 0 && (
              <div
                style={{
                  // eslint-disable-next-line no-nested-ternary
                  paddingTop: matchesMediaQuery
                    ? 16
                    : isMixMatchPage
                    ? 120
                    : 40,
                }}
              >
                {showLookBoardView && (
                  <div className="row mb-3">
                    {lookBoards.map((lookBoard) => (
                      <div
                        key={lookBoard.id}
                        className={clsx('px-1 pb-5', {
                          'col-4': !matchesMediaQuery,
                          'w-100': matchesMediaQuery,
                        })}
                      >
                        <LookBoardItem
                          lookBoard={lookBoard}
                          user={users[lookBoard.userId]}
                          products={products}
                          onToggleLike={handleToggleLikeLookBoard}
                          onVote={handleVoteLookBoard}
                          onOpenModal={onOpenLookBoardModal}
                          hideLikeVote
                        />
                      </div>
                    ))}
                  </div>
                )}
                {activeTab === getTheLookTabKeys.productStream && (
                  <div className="mt-5">
                    <ProductsStream
                      products={products}
                      users={users}
                      onToggleLikeProduct={handleToggleLikeProduct}
                    />
                  </div>
                )}
                {activeTab === getTheLookTabKeys.mixAndMatch && (
                  <div
                    className={clsx(
                      'd-flex justify-content-between',
                      classes.mixMatchContainer
                    )}
                  >
                    <MixAndMatchView
                      products={products}
                      onSave={handleSaveMixAndMatch}
                      isDetailsPage
                    />
                    <div className="d-flex flex-column">
                      <Button
                        variant="outlined"
                        onClick={handleViewLookBoard}
                        className={clsx(classes.rightSideBtn)}
                      >
                        View Matching Look Boards
                      </Button>
                      <Button
                        variant="outlined"
                        className={clsx(classes.rightSideBtn, 'mt-1')}
                        onClick={handleViewProducts}
                      >
                        View Matching Products
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {isLookBoard && !loading && Object.keys(lookBoardsData).length && (
              <div
                style={{
                  // eslint-disable-next-line no-nested-ternary
                  paddingTop: matchesMediaQuery
                    ? 16
                    : isMixMatchPage
                    ? 120
                    : 120,
                }}
              >
                <MixAndMatchView
                  products={similarProductGroups}
                  onSave={handleSaveMixAndMatch}
                  isDetailsPage
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
      <LookBoardPreviewModal
        open={Boolean(lookBoardForModal)}
        onClose={closeLookBoardForModal}
        lookBoard={lookBoardForModal}
      />
    </>
  );
};

DetailsView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      imageId: PropTypes.string.isRequired,
      lookBoardId: PropTypes.string,
    }).isRequired,
  }),
  currentImage: inspirationImageShape,
  activeTab: PropTypes.string.isRequired,
  searchParams: PropTypes.shape({}).isRequired,
  lookBoards: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.shape({}),
  users: PropTypes.shape({}),
  lookBoardsData: PropTypes.shape({}).isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  syncInspirationImage: PropTypes.func.isRequired,
  toggleLikeLookBoard: PropTypes.func.isRequired,
  voteLookBoard: PropTypes.func.isRequired,
  toggleLikeProduct: PropTypes.func.isRequired,
  createLookBoard: PropTypes.func.isRequired,
  createRequest: PropTypes.func.isRequired,
  updateMixMatchType: PropTypes.func.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  isLookBoard: PropTypes.bool,
};

DetailsView.defaultProps = {
  currentImage: null,
  lookBoards: null,
  products: {},
  users: {},
  isLookBoard: false,
  match: {
    params: {
      lookBoardId: null,
    },
  },
};

const mapStateToProps = (
  {
    app: { config },
    getTheLook: { activeTab, searchParams, lookBoardsData },
    inspirationImage: { library },
    auth: { user },
  },
  {
    match: {
      params: { imageId },
    },
  }
) => ({
  currentImage: library[imageId],
  activeTab,
  searchParams,
  lookBoards: lookBoardsData[imageId]?.lookBoards,
  products:
    lookBoardsData[imageId] &&
    transformArrayToMap(lookBoardsData[imageId].products),
  users:
    lookBoardsData[imageId] &&
    transformArrayToMap(lookBoardsData[imageId].users),
  inspirationImageUrl: getInspirationImgUrl(config),
  authenticated: Boolean(user),
  lookBoardsData,
});

const mapDispatchToProps = {
  syncInspirationImage: syncInspirationImageAction,
  toggleLikeLookBoard: toggleLikeLookBoardAction,
  voteLookBoard: voteLookBoardAction,
  toggleLikeProduct: toggleLikeProductAction,
  createLookBoard: selectImageCTL,
  createRequest: selectImageRTL,
  updateMixMatchType: updateMixMatchTypeAction,
  changeActiveTab: changeActiveTabAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsView);
