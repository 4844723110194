import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { productShape } from 'modules/product/propTypes';
import ProductThumbnail from 'components/ProductThumbnail/ProductThumbnail';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import { routesByName } from 'constants/routes';
import SharePopover from 'components/modals/SharePopover/SharePopover';
import clsx from 'clsx';
import classes from '../../product/ProductItemDetails/ProductItemDetails.module.scss';
import productThumbnailClasses from '../../../components/ProductThumbnail/ProductThumbnail.module.scss';
import Button from '../../../components/ui/Button/Button';
import IconButton from '../../../components/ui/IconButton/IconButton';
import SpriteIcon from '../../../components/ui/SpriteIcon';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../constants/mediaQueries';

const ProductItem = ({
  product: {
    id,
    name,
    media,
    itemClassId,
    price,
    isLiked,
    shareUrl,
    inLookBoards,
    slug,
  },
  onToggleLike,
}) => {
  const authenticated = Boolean(useSelector((state) => state.auth.user));

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const history = useHistory();
  const { pathname } = useLocation();

  const [shareAnchorEl, setShareAnchorEl] = useState(null);

  /* const avatarUrl = useMemo(
    () =>
      user.avatar &&
      buildResourceUrl(userAvatarUrl, user.avatar.userId, user.avatar.hash),
    [userAvatarUrl, user]
  );
 */
  const productPrice = useMemo(() => {
    const calculatedPrice = price / 100;
    return Number.isNaN(calculatedPrice) ? 0 : calculatedPrice;
  }, [price]);

  const handleToggleLike = useCallback(() => {
    if (!authenticated) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    onToggleLike(id, Number(!isLiked));
  }, [authenticated, onToggleLike, id, isLiked, history, pathname]);

  const moveToProduct = useCallback(() => {
    history.push(routesByName.product.details(id, slug));
  }, [history, id, slug]);

  const moveToDetails = useCallback(() => {
    history.push(routesByName.lookBoard.details(inLookBoards[0]));
  }, [history, inLookBoards]);

  /* const handleOpenSharePopover = useCallback(({ currentTarget }) => {
    setShareAnchorEl(currentTarget);
  }, []); */

  const handleCloseSharePopover = useCallback(() => {
    setShareAnchorEl(null);
  }, []);

  return (
    <>
      <ProductThumbnail
        name={name}
        media={media}
        itemClassId={itemClassId}
        price={productPrice}
        onViewLookBoard={inLookBoards?.length > 0 ? moveToDetails : undefined}
        isLiked={isLiked}
        staticOverlay={
          <OverlayLabel
            text="product is discontinued"
            labelClasses={{ label: 'text-lg' }}
          />
        }
        hoverOverlay={
          matchesMediaQuery ? null : (
            <div className="h-100 d-flex flex-column">
              <div
                className={clsx('flex-fill flex-center', classes.imageWrapper)}
              >
                <span
                  className="text-capitalize text-center cursor-pointer"
                  onClick={moveToProduct}
                >
                  View item details
                </span>
              </div>
              <div className={clsx('d-flex flex-column', classes.bottomBar)}>
                <div
                  className={clsx(
                    'd-flex justify-content-between align-items-center',
                    classes.productLabel
                  )}
                >
                  <div className="py-1">
                    <p
                      title={name}
                      className={`${productThumbnailClasses.productName} ${classes.productName} text-ellipsis text-sm text-gray font-semi-bold`}
                    >
                      {name}
                    </p>
                    <span className="primary-color text-sm font-semi-bold">
                      {productPrice ? `$${productPrice}` : 'N/A'}
                    </span>
                  </div>
                  <Button
                    variant="contained"
                    inline
                    size="xs"
                    onClick={moveToProduct}
                  >
                    <span className="px-2">VIEW</span>
                  </Button>
                </div>
                <div
                  className={clsx(
                    'd-flex align-items-center py-1',
                    !inLookBoards?.length
                      ? 'justify-content-end'
                      : 'justify-content-between'
                  )}
                >
                  {inLookBoards?.length > 0 && (
                    <Button
                      variant="outlined"
                      inline
                      size="xs"
                      className={classes.lookBoardsBtn}
                      onClick={moveToDetails}
                    >
                      <span className="px-2">View in Look Boards</span>
                    </Button>
                  )}

                  <IconButton
                    size="sm"
                    variant="inverted-grey"
                    onClick={handleToggleLike}
                  >
                    <SpriteIcon
                      name={isLiked ? 'like-filled' : 'like'}
                      size="sm"
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          )
        }
      />
      <SharePopover
        anchorEl={shareAnchorEl}
        open={Boolean(shareAnchorEl)}
        onClose={handleCloseSharePopover}
        placement="bottom"
        shareUrl={shareUrl}
      />
    </>
  );
};

ProductItem.propTypes = {
  product: productShape.isRequired,
  onToggleLike: PropTypes.func.isRequired,
};

export default memo(ProductItem);
