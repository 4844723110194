import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getEnumAsSelectOptions,
  selectFilterValues,
} from 'modules/app/store/selectors';
import { priorityItemShape } from 'modules/requestTheLook/requestDetails/requestForm/propTypes';
import { Field, Form, FormSpy } from 'react-final-form';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import {
  requestFormFields,
  requestTargetRoomTypes,
} from 'modules/requestTheLook/requestDetails/requestForm/constants';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import Button from 'components/ui/Button/Button';
import { requestTypesTitles } from 'modules/requestTheLook/constants';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import SelectInput from 'components/inputs/SelectInput/SelectInput';
import { filterTabKeys } from 'components/modals/FiltersModal/constants';
import FiltersModal from 'components/modals/FiltersModal/FiltersModal';
import RoomRequest from 'modules/requestTheLook/requestDetails/components/RoomRequest/RoomRequest';
import SingleItemRequest from 'modules/requestTheLook/requestDetails/components/SingleItemRequest/SingleItemRequest';
import RequestMessage from 'modules/requestTheLook/requestDetails/components/RequestMessage/RequestMessage';
import classes from './RequestForm.module.scss';

const RequestFormComponent = ({
  onSubmit,
  onFormChange,
  validateForm,
  initialValues,
  requestPriorityItems,
  addAnotherItemEnable,
  optionsList,
  requestType,
  handleChangeTabOption,
  handleChangeSingleType,
  handleOpenInfoModal,
  handleChangeShowTabsLimit,
  showTabsLimit,
  roomType,
  handleResetModalOpen,
  handleChangeRoomType,
  handleAddPriorityItem,
  onMessageChange,
  handleChangeRequestStyleAndColor,
  requestColor,
  requestStyle,
  resetImageFilterValues,
}) => {
  const enums = useSelector((state) => state.app.enums);
  const requestData = useSelector((state) => state.requestTheLook.requestData);
  const searchParams = useSelector(
    (state) => state.curateTheLook.inspirationImageSearchParams
  );
  const { itemsForTabs, message, title } = requestData;
  const imageFilterValues = useMemo(() => selectFilterValues(requestData), [
    requestData,
  ]);
  const roomTypesOptions = useMemo(
    () =>
      getEnumAsSelectOptions(enums.roomTypes)
        .filter((room) => requestTargetRoomTypes.includes(room.label))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [enums.roomTypes]
  );

  const [formDefaultValues, setFormDefaultValues] = useState(initialValues);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  useEffect(() => {
    if (roomType) {
      roomTypesOptions.forEach((e) => {
        if (e.value === roomType) {
          if (title && title !== `${e.label} Request`) {
            setFormDefaultValues({
              title,
            });
          } else {
            setFormDefaultValues({
              title:
                requestType === imageTypeKeys.makeOver
                  ? `${e.label} Makeover Request`
                  : `${e.label} Request`,
            });
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [roomTypesOptions, roomType]);

  const getErrorFilter = (array, count) => {
    return !(!array?.length || array?.length > count);
  };

  const handleCloseFiltersModal = useCallback(() => {
    setFiltersModalOpen(false);
  }, []);

  const handleApplyFilters = useCallback(
    (filters) => {
      handleChangeRequestStyleAndColor(filters);
    },
    [handleChangeRequestStyleAndColor]
  );

  return (
    <>
      <Form
        initialValues={formDefaultValues}
        validate={validateForm}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            className="d-flex flex-column h-100 justify-content-between"
          >
            <FormSpy onChange={onFormChange} />

            <div>
              <h2
                className={`${classes.title} d-flex align-items-center justify-content-between `}
              >
                {requestTypesTitles[requestType]}
                <button
                  onClick={handleResetModalOpen}
                  className={`${classes.underlineBtn} primary-color text-lg`}
                  type="button"
                >
                  Reset
                </button>
              </h2>
              <Button
                onClick={handleOpenInfoModal}
                variant="default"
                size="sm"
                type="button"
                className={classes.learnMoreBtn}
              >
                Learn more
              </Button>
              <div className="d-flex flex-column flex-fill">
                <div className="mb-1 row">
                  <div className="col-4">
                    <div className="d-flex align-items-center mb-2">
                      <div className="text-sm font-semi-bold mr-1 position-relative">
                        Request Title<span className="secondary-color">*</span>
                        <div className={classes.titlePopover}>
                          <InfoIconPopover
                            iconProps={{
                              variant: 'inverted-grey',
                              color: 'secondary',
                              size: 'xs',
                            }}
                            fullWidth
                          >
                            <p className="font-semi-bold mb-1">
                              Title Description
                            </p>
                            <p className="text-xs">
                              Select up to 6 priority items from your
                              inspiration image. Our decor lovers will then
                              create Look Board recommendations that will
                              include one or more of these priority items
                            </p>
                          </InfoIconPopover>
                        </div>
                      </div>
                    </div>
                    <Field
                      required
                      name={requestFormFields.title}
                      component={TextInputWrapper}
                      placeholder="Add title here"
                    />
                  </div>
                  <div className="col-1" />

                  <div className="col-4">
                    {(requestType === imageTypeKeys.room ||
                      requestType === imageTypeKeys.makeOver) && (
                      <>
                        <div className="d-flex align-items-center mb-2">
                          <div className="text-sm font-semi-bold mr-1">
                            Room Type<span className="secondary-color">*</span>
                          </div>
                        </div>
                        <SelectInput
                          classes={{ select: classes.select }}
                          options={roomTypesOptions}
                          value={roomType ?? ''}
                          onChange={handleChangeRoomType}
                        />
                      </>
                    )}
                  </div>
                </div>
                {requestType === imageTypeKeys.makeOver && (
                  <div className="mb-5 row">
                    <div className="col-4">
                      <div className="d-flex align-items-center mb-2">
                        <div className="text-sm font-semi-bold mr-1 position-relative">
                          Select Preferences
                          <span className="secondary-color">*</span>
                        </div>
                      </div>
                      <Button
                        size="sm"
                        variant="outlined"
                        className="d-flex align-items-center px-3"
                        onClick={() => setFiltersModalOpen(true)}
                        type="button"
                      >
                        <span className={classes.pinBtnText}>
                          Styles & Colors
                        </span>
                      </Button>
                    </div>
                    <div className="col-1" />
                  </div>
                )}
                {requestType === imageTypeKeys.room && (
                  <RoomRequest
                    requestPriorityItems={requestPriorityItems}
                    itemsForTabs={itemsForTabs}
                    handleChangeShowTabsLimit={handleChangeShowTabsLimit}
                    showTabsLimit={showTabsLimit}
                    handleAddPriorityItem={handleAddPriorityItem}
                    addAnotherItemEnable={addAnotherItemEnable}
                    optionsList={optionsList}
                    handleChangeTabOption={handleChangeTabOption}
                  />
                )}
                {requestType === imageTypeKeys.makeOver && (
                  <RoomRequest
                    requestPriorityItems={requestPriorityItems}
                    itemsForTabs={itemsForTabs}
                    handleChangeShowTabsLimit={handleChangeShowTabsLimit}
                    showTabsLimit={showTabsLimit}
                    handleAddPriorityItem={handleAddPriorityItem}
                    addAnotherItemEnable={addAnotherItemEnable}
                    optionsList={optionsList}
                    handleChangeTabOption={handleChangeTabOption}
                  />
                )}
                {requestType === imageTypeKeys.single && (
                  <SingleItemRequest
                    requestPriorityItems={requestPriorityItems}
                    handleChangeSingleType={handleChangeSingleType}
                    optionsList={optionsList}
                  />
                )}
                <div className={classes.messageContainer}>
                  <div className="text-sm mb-1">
                    <b>Add Message (optional)</b>
                  </div>
                  <RequestMessage
                    placeholder="Add a message to our community of decor lovers here."
                    onChange={onMessageChange}
                    messageText={message}
                  />
                </div>
              </div>
            </div>

            <div className={classes.formButtons}>
              <FinalFormSubmitButton
                variant="outlined"
                size="lg"
                inline
                disabled={
                  requestType === imageTypeKeys.makeOver
                    ? !getErrorFilter(requestColor, 3) ||
                      !getErrorFilter(requestStyle, 3)
                    : false
                }
              >
                Review
              </FinalFormSubmitButton>
            </div>
          </form>
        )}
      />
      <FiltersModal
        disabledFilters={[
          filterTabKeys.vibes,
          filterTabKeys.itemClasses,
          filterTabKeys.roomTypes,
          filterTabKeys.subStyles,
          filterTabKeys.videos,
          filterTabKeys.metaTags,
          filterTabKeys.prices,
          filterTabKeys.shapes,
          filterTabKeys.materials,
        ]}
        open={filtersModalOpen}
        onClose={handleCloseFiltersModal}
        imageType={searchParams.type}
        filterValues={imageFilterValues}
        onApply={handleApplyFilters}
        onReset={resetImageFilterValues}
        maxSelections={3}
      />
    </>
  );
};

RequestFormComponent.propTypes = {
  validateForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  requestPriorityItems: PropTypes.arrayOf(priorityItemShape).isRequired,
  optionsList: PropTypes.arrayOf(priorityItemShape).isRequired,
  addAnotherItemEnable: PropTypes.bool.isRequired,
  requestType: PropTypes.string.isRequired,
  handleChangeTabOption: PropTypes.func.isRequired,
  handleChangeRoomType: PropTypes.func.isRequired,
  handleChangeRequestStyleAndColor: PropTypes.func.isRequired,
  handleChangeSingleType: PropTypes.func.isRequired,
  handleOpenInfoModal: PropTypes.func.isRequired,
  handleChangeShowTabsLimit: PropTypes.func.isRequired,
  showTabsLimit: PropTypes.number.isRequired,
  handleResetModalOpen: PropTypes.func.isRequired,
  roomType: PropTypes.number,
  requestColor: PropTypes.arrayOf(PropTypes.number),
  requestStyle: PropTypes.arrayOf(PropTypes.number),
  handleAddPriorityItem: PropTypes.func.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  resetImageFilterValues: PropTypes.func.isRequired,
};

RequestFormComponent.defaultProps = {
  roomType: null,
  requestColor: [],
  requestStyle: [],
};

export default RequestFormComponent;
