import React, { useCallback, useRef, useState } from 'react';
import buildResourceUrl from 'utils/buildResourceUrl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from 'components/ui/Button/Button';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import MoreOptionsPopover from 'modules/curateTheLook/createLookBoard/components/MoreOptionsPopover/MoreOptionsPopover';
import SharePopover from 'components/modals/SharePopover/SharePopover';
import moreOptionsClasses from 'modules/curateTheLook/createLookBoard/components/MoreOptionsPopover/MoreOptionsPopover.module.scss';
import { routesByName } from 'constants/routes';
import { useHistory, useLocation } from 'react-router-dom';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import errorToastr from 'libs/toastr/errorToastr';
import { updateIIStatusLikeAction } from 'modules/inspirationImage/store/actions';
import {
  resetCanvasAction,
  selectImageAction as selectCTLImageAction,
} from 'modules/curateTheLook/store/actions';
import { selectImageAction as selectRTLImageAction } from 'modules/requestTheLook/store/actions';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import ImageLink from 'components/imageThumbnails/ImageLink/ImageLink';
import { maxWidthMd } from 'constants/mediaQueries';
import {
  setClaimImageIdAction,
  toggleClaimImageModalAction,
} from '../../report/store/actions';
import classes from '../GetTheLook.module.scss';
import { publishStatusKeys } from '../../inspirationImage/constants';
import { GTL_UNLIKE_IMAGE } from '../store/constants';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { changeActiveTabAction } from '../store/actions';
import { getTheLookTabKeys } from '../constants';

const GalleryItem = ({
  image: {
    id,
    isLiked,
    shareUrl,
    media: { userId, hash },
    title,
    image_source_name: imageSource,
    source_url: sourceUrl,
    publish,
    slug,
  },
  isShowcase,
  onLikeSimilarImage,
  likedImages,
  disabled,
}) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const { pathname } = useLocation();
  const imageRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const authenticated = Boolean(useSelector((state) => state.auth.user));
  const selectedImage = Boolean(
    useSelector((state) => state.curateTheLook.lookBoardData.inspirationImageId)
  );
  const inspirationImageUrl = useSelector(
    (state) => state.app.config.endpoints.media.inspirationImage
  );
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);
  const imgUrl = buildResourceUrl(inspirationImageUrl.medium, userId, hash);

  const handleMoreOptionsPopupOpen = useCallback((event) => {
    event.stopPropagation();
    setMoreOptionsAnchorEl(event.currentTarget);
    setIsOptionOpen(true);
    setScrollPosition(window.pageYOffset);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const handleOpenPreview = useCallback(
    (event) => {
      event.stopPropagation();
      setImgPreviewUrl(imgUrl);
      setImgModalOpen(true);
    },
    [imgUrl]
  );

  // set default scroll position after closing "MoreOptionPopup"
  const handleMoreOptionsPopupClose = useCallback(() => {
    setMoreOptionsAnchorEl(null);
    setIsOptionOpen(false);
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 0);
  }, [scrollPosition]);

  const handleSelectImage = useCallback(() => {
    /* if (!currentUser) {
      history.push(`?${routesByName.auth.key}=${routesByName.auth.signUp}`);
      return;
    } */
    if (isShowcase) {
      if (publish === publishStatusKeys.publishedPlus)
        window.open(routesByName.getTheLook.details(id, slug), '_blank');
      else
        window.open(routesByName.inspirationImage.details(id, slug), '_blank');
      return;
    }
    if (publish === publishStatusKeys.publishedPlus)
      history.push(routesByName.getTheLook.details(id, slug));
    else history.push(routesByName.inspirationImage.details(id, slug));
  }, [history, id, isShowcase, publish, slug]);

  const handleOnClaimImage = useCallback(() => {
    dispatch(toggleClaimImageModalAction(true));
    dispatch(setClaimImageIdAction(id));
    handleMoreOptionsPopupClose();
  }, [dispatch, id, handleMoreOptionsPopupClose]);

  const handleMoveToRTL = useCallback(() => {
    dispatch(selectRTLImageAction(id));
    history.push(routesByName.requestTheLook.index);
  }, [dispatch, id, history]);

  const handeMixMatch = useCallback(() => {
    if (!currentUser) {
      history.push(`${pathname}?info=register-mix-match`);
      return;
    }
    dispatch(changeActiveTabAction(getTheLookTabKeys.mixAndMatch));
    history.push(routesByName.getTheLook.mixMatch.details(id));
  }, [currentUser, dispatch, history, id, pathname]);

  const handleProducts = useCallback(() => {
    history.push(routesByName.getTheLook.details(id, slug));
    dispatch(changeActiveTabAction(getTheLookTabKeys.productStream));
  }, [history, id, slug, dispatch]);

  const handleMoveToCTL = useCallback(() => {
    if (id !== selectedImage) {
      dispatch(resetCanvasAction());
    }
    if (pathname === routesByName.getTheLook.index) {
      dispatch(selectCTLImageAction(id));
      history.push(
        `${routesByName.curateTheLook.canvasBySlug(slug)}/?tips=active`
      );
    } else {
      dispatch(selectCTLImageAction(id));
      history.push(routesByName.curateTheLook.index);
    }
  }, [id, selectedImage, pathname, dispatch, history, slug]);

  const handleCloseSharePopover = useCallback(() => {
    setShareAnchorEl(null);
  }, []);

  const handleToggleLike = useCallback(
    async (event) => {
      event.stopPropagation();
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      if (onLikeSimilarImage) {
        try {
          await inspirationImageService.toggleLike(id, Number(!isLiked));
          onLikeSimilarImage();
        } catch (e) {
          errorToastr('Error', e.generalError);
        }
      } else {
        try {
          await inspirationImageService.toggleLike(id, Number(!isLiked));
          if (likedImages)
            dispatch({ type: GTL_UNLIKE_IMAGE, payload: { id } });
          else dispatch(updateIIStatusLikeAction(id, Number(!isLiked)));
        } catch (e) {
          errorToastr('Error', e.generalError);
        }
      }
    },
    [
      authenticated,
      onLikeSimilarImage,
      history,
      pathname,
      id,
      isLiked,
      likedImages,
      dispatch,
    ]
  );

  const [hover, setHover] = useState(false);

  const handleHover = useCallback(() => {
    setHover(true);
  }, []);

  const handleBlur = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div
      className={clsx(!isShowcase && 'px-2', classes.galleryContainer)}
      onMouseEnter={!matchesMediaQuery ? handleHover : null}
      onMouseLeave={!matchesMediaQuery ? handleBlur : null}
    >
      <div
        className={clsx(
          'position-relative',
          !disabled && 'cursor-pointer',
          classes.galleryImage,
          isShowcase && classes.featuredImage
        )}
      >
        <img
          alt=""
          draggable="false"
          src={imgUrl}
          ref={imageRef}
          className="object-fit-cover"
        />
        <div
          className={clsx(classes.overlay, 'p-1', {
            [classes.hidden]: !hover && !matchesMediaQuery,
            [classes.mobileHover]: matchesMediaQuery,
          })}
        >
          {disabled && <div className={classes.greyOverlay} />}
          <>
            <div className="h-100 flex-column flex-vertical-center justify-content-between">
              <div className="d-flex flex-column align-items-center">
                <IconButton
                  color="secondary"
                  size="sm"
                  onClick={handleOpenPreview}
                  className={clsx(
                    classes.loope,
                    isShowcase && classes.featuredLoope
                  )}
                >
                  <SpriteIcon name="loupe" size="sm" />
                </IconButton>
                <p
                  className={!disabled && classes.hoverLabel}
                  onClick={handleSelectImage}
                >
                  Get The Look
                </p>
                <div className={classes.hoverButtonsCont}>
                  <Button
                    onClick={handeMixMatch}
                    variant="custom"
                    className={classes.hoverButton}
                  >
                    Mix & Match
                  </Button>
                  <Button
                    onClick={handleProducts}
                    variant="custom"
                    className={classes.hoverButton}
                  >
                    View Products
                  </Button>
                  <Button
                    onClick={handleMoveToCTL}
                    variant="custom"
                    className={classes.hoverButton}
                  >
                    Curate The Look
                  </Button>
                </div>
              </div>
              <div className="w-100 d-flex flex-column p-1">
                <div className="flex-fill text-ellipsis">
                  {sourceUrl && <ImageLink url={sourceUrl} />}
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <span
                      className={clsx(
                        classes.imageTitle,
                        classes.imageHoverTitle,
                        'text-ellipsis'
                      )}
                    >
                      {title}
                    </span>
                    <p className={classes.imageHoverTitle}>{imageSource}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <IconButton
                      variant="inverted-grey"
                      size="sm"
                      className="mr-1"
                      onClick={handleToggleLike}
                    >
                      <SpriteIcon
                        name={isLiked ? 'like-filled' : 'like'}
                        size="sm"
                      />
                    </IconButton>
                    {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
                    <IconButton
                      variant="inverted-grey"
                      size="sm"
                      onClick={handleMoreOptionsPopupOpen}
                      onMouseEnter={handleMoreOptionsPopupOpen}
                    >
                      <SpriteIcon name="more-vertical" size="sm" />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
            <MoreOptionsPopover
              open={isOptionOpen}
              onClose={handleMoreOptionsPopupClose}
              anchorEl={moreOptionsAnchorEl}
              scrollPosition={scrollPosition}
            >
              <Button
                className={`${moreOptionsClasses.btn} mr-1`}
                size="custom"
                color="default"
                onClick={handleMoveToRTL}
              >
                <SpriteIcon name="update-arrows" size="sm" className="mr-1" />
                <span>Request</span>
              </Button>
              <Button
                className={`${moreOptionsClasses.btn} mr-1`}
                size="custom"
                color="default"
                onClick={handleMoveToCTL}
              >
                <SpriteIcon name="living-room" size="sm" className="mr-1" />
                <span>Curate</span>
              </Button>
              <Button
                onClick={handleOnClaimImage}
                className={moreOptionsClasses.btn}
                size="custom"
                color="default"
                style={{ minWidth: 90 }}
              >
                <span>Claim Image</span>
              </Button>
            </MoreOptionsPopover>
            <SharePopover
              anchorEl={shareAnchorEl}
              open={Boolean(shareAnchorEl)}
              onClose={handleCloseSharePopover}
              shareUrl={shareUrl}
              directImgUrl={imgUrl}
            />
          </>
        </div>
        <ImgPreviewModal
          open={imgModalOpen}
          onClose={handleImgModalClose}
          url={imgPreviewUrl}
        />
      </div>
      {/*  {publish !== publishStatusKeys.publishedPlus && (
        <>
          <span className={clsx(classes.imageTitle, 'text-ellipsis')}>
            {title}
          </span>
          <p className={classes.imageSubTitle}>{imageSource}</p>
        </>
      )} */}
      {!isShowcase && (
        <Button
          onClick={handleSelectImage}
          className={classes.galleryBtn}
          variant="outlined"
        >
          Get The look
        </Button>
      )}
    </div>
  );
};

GalleryItem.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    shareUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image_source_name: PropTypes.string,
    isLiked: PropTypes.number.isRequired,
    source_url: PropTypes.string,
    media: PropTypes.shape({
      userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      hash: PropTypes.string.isRequired,
    }).isRequired,
    publish: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
  isShowcase: PropTypes.bool,
  onLikeSimilarImage: PropTypes.func,
  likedImages: PropTypes.bool,
  disabled: PropTypes.bool,
};

GalleryItem.defaultProps = {
  isShowcase: false,
  onLikeSimilarImage: null,
  likedImages: false,
  disabled: false,
  image: {
    image_source_name: '',
    source_url: '',
  },
};

export default GalleryItem;
