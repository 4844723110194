import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import ListView from '../../ListView';
import { getTheLookTabKeys } from '../../constants';
import { changeActiveTabAction } from '../../store/actions';
import ProductsList from './components/ProductsList';
import { routesByName } from '../../../../constants/routes';

const ProductView = ({ setStyleQuizModalOpen }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeActiveTabAction(getTheLookTabKeys.productStream));
  }, []);

  return (
    <>
      <Route exact path={routesByName.getTheLook.products.room}>
        <ListView setStyleQuizModalOpen={setStyleQuizModalOpen} />
      </Route>
      <Route exact path={routesByName.getTheLook.products.productType}>
        <ProductsList />
      </Route>
    </>
  );
};

ProductView.propTypes = {
  setStyleQuizModalOpen: PropTypes.func.isRequired,
};
export default memo(ProductView);
