import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import FilterContent from 'modules/requestTheLook/requestDetails/components/FilterModal/FilterContent';
import FilterTabs from 'modules/requestTheLook/requestDetails/components/FilterModal/FilterTabs';
import { filterModalArrayOf } from 'modules/requestTheLook/requestDetails/components/FilterModal/propTypes';

const ItemClassesFilter = ({ options, currentValue, onChange }) => {
  const shouldDisableCheckbox = useCallback(() => {
    return false;
  }, []);

  const items = useMemo(
    () =>
      options.map(({ name, types }) => {
        const sortedTypes = [...types];

        if (name === 'HOME ACCENTS') {
          const element = sortedTypes.splice(2, 1)[0];

          sortedTypes.push(element);
        }

        return (
          <FilterContent
            key={name}
            title={name}
            types={sortedTypes}
            checkedItems={currentValue}
            onChange={onChange}
            shouldDisableCheckbox={shouldDisableCheckbox}
          />
        );
      }),
    [currentValue, onChange, options, shouldDisableCheckbox]
  );
  return <FilterTabs>{items}</FilterTabs>;
};

ItemClassesFilter.propTypes = {
  options: filterModalArrayOf.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(ItemClassesFilter);
