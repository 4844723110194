import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import GalleryItem from 'modules/getTheLook/components/GalleryItem';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import ImageLink from 'components/imageThumbnails/ImageLink/ImageLink';
import IconButton from '@material-ui/core/IconButton';
import classes from './SingleInspirationModal.module.scss';
import SpriteIcon from '../../../../components/ui/SpriteIcon';

const SingleInspirationModal = ({ open, onClose, currentImage }) => {
  return (
    <div
      className={clsx(classes.hidden, classes.modal, { [classes.open]: open })}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        className={clsx(classes.close)}
      >
        <SpriteIcon name="cross" size="sm" />
      </IconButton>
      <div>
        <h3 className="text-xl text-center font-title text-uppercase">
          Inspiration image
        </h3>
        {currentImage && (
          <div
            className={clsx(
              classes.imageWrapper,
              currentImage.source_url && classes.linkPadding
            )}
          >
            {/* eslint-disable-next-line */}
            {currentImage.source_url && (
              <div
                className={clsx(
                  classes.selectedImageLink,
                  'text-ellipsis',
                  'mb-3'
                )}
              >
                {/* eslint-disable-next-line */}
                <ImageLink url={currentImage?.source_url} color="white" />
              </div>
            )}
            <GalleryItem
              disabled={currentImage.publish !== 'publishedPlus'}
              image={currentImage}
              isShowcase
            />
          </div>
        )}
      </div>
    </div>
  );
};

SingleInspirationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentImage: inspirationImageShape,
};

SingleInspirationModal.defaultProps = {
  currentImage: null,
};
export default SingleInspirationModal;
