import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import errorToastr from 'libs/toastr/errorToastr';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import { imagePageTypes } from 'constants/inspirationImageSearchParams';
import { filterTabKeys } from 'components/modals/FiltersModal/constants';
import FiltersModal from 'components/modals/FiltersModal/FiltersModal';
import SearchInput from 'components/inputs/SearchInput/SearchInput';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ScrollableDatabase from 'modules/curateTheLook/createLookBoard/components/ScrollableDatabase';
import useCallbackRef from 'hooks/useCallbackRef';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import useCancelToken from 'hooks/useCancelToken';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import transformArrayToMap from 'utils/transformArrayToMap';
import {
  lookBoardSortKeys,
  lookBoardSourceKeys,
} from 'constants/lookBoardSearchParams';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';
import { updateLookboardsLibraryAction } from 'modules/showcase/store/actions';
import { setShowcaseActiveTab } from '../../store/actions';
import { dropItemType } from '../constants';
import classes from '../Showcase.module.scss';
import ShowcaseLookBoardItem from './ShowcaseLookBoardItem';
import { selectImageAction as selectCTLImageAction } from '../../../curateTheLook/store/actions';

const initialImagesSearchParams = {
  onlyApproved: false,
  source: 'my',
  offset: 0,
  limit: 10,
  pageType: imagePageTypes.showcase,
  search: '',
  colors: [],
  styles: [],
  subStyles: [],
  roomTypes: [],
  itemClasses: [],
};

const initialShowcaseLookBoardsSearchParams = {
  limit: 10,
  source: lookBoardSourceKeys.my,
  sort: lookBoardSortKeys.newest,
  onlyApproved: false,
  offset: 0,
};

const RightDragPanel = () => {
  const dispatch = useDispatch();
  const [createCancelToken] = useCancelToken();
  const history = useHistory();

  const showcase = useSelector((state) => state.showcase.current);
  const showcaseLookBoardsLibrary = useSelector(
    (state) => state.showcase.lookBoardsLibrary
  );
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );

  const activeTab = useSelector((state) => state.dashboard.showcaseActiveTab);
  // eslint-disable-next-line
  const [scrollBarNode, scrollBarRef] = useCallbackRef();
  const [scrollBarRootNode, scrollBarRootRef] = useCallbackRef();
  const [
    initialScrollableDatabaseHeight,
    setInitialScrollableDatabaseHeight,
  ] = useState(0);
  const inputRef = useRef(null);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [showcaseImagesIds, setShowcaseImagesIds] = useState([]);
  const [showcaseImagesLibrary, setShowcaseImagesLibrary] = useState({});
  const [imagesSearchParams, setImagesSearchParams] = useState(
    initialImagesSearchParams
  );
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const [hasLookBoardsMore, setHasLookBoardsMore] = useState(true);
  const [loadingLookBoards, setLookBoardsLoading] = useState(false);
  const [showcaseLookBoardIds, setShowcaseLookBoardIds] = useState([]);
  const [
    showcaseLookBoardsSearchParams,
    setShowcaseLookBoardsSearchParams,
  ] = useState(initialShowcaseLookBoardsSearchParams);

  const handleOpenFiltersModal = useCallback(() => {
    setFiltersModalOpen(true);
  }, []);

  const handleCloseFiltersModal = useCallback(() => {
    setFiltersModalOpen(false);
  }, []);

  const updateShowcaseImageLibrary = useCallback((imagesMap) => {
    setShowcaseImagesLibrary((prevState) => ({ ...prevState, ...imagesMap }));
  }, []);

  const updateImagesSearchParams = useCallback((params) => {
    setImagesSearchParams((prevState) => ({
      ...prevState,
      ...params,
      offset: params.offset ?? 0,
    }));
  }, []);

  const resetImagesSearchParams = useCallback(() => {
    setImagesSearchParams(initialImagesSearchParams);
  }, []);

  const handleChangeSearchQuery = useCallback(
    (search) => {
      setShowcaseImagesIds([]);
      updateImagesSearchParams({ search });
    },
    [updateImagesSearchParams]
  );

  const handleCrossClick = useCallback(() => {
    updateImagesSearchParams({ search: '' });
  }, [updateImagesSearchParams]);

  const loadFeaturedImages = useCallback(async () => {
    try {
      setLoading(true);

      const cancelToken = createCancelToken();
      const [
        transformedImages,
        imageList,
      ] = await inspirationImageService.getImages(imagesSearchParams, {
        cancelToken,
      });

      updateImagesSearchParams({ offset: imageList.length });
      setHasMore(imageList.length === imagesSearchParams.limit);
      updateShowcaseImageLibrary(transformedImages);
      setShowcaseImagesIds(imageList.map(({ id }) => id));

      dispatch(updateIILibraryAction(transformedImages));
    } catch (e) {
      errorToastr('Error', e.message);
    } finally {
      setLoading(false);
    }
  }, [
    createCancelToken,
    imagesSearchParams,
    updateImagesSearchParams,
    updateShowcaseImageLibrary,
    dispatch,
  ]);

  const loadMoreFeaturedImages = useCallback(async () => {
    if (!hasMore || loading) return;

    try {
      setLoading(true);
      const cancelToken = createCancelToken();
      const [
        transformedImages,
        imageList,
      ] = await inspirationImageService.getImages(imagesSearchParams, {
        cancelToken,
      });

      updateImagesSearchParams({
        offset: showcaseImagesIds.length + imageList.length,
      });
      setHasMore(imageList.length === imagesSearchParams.limit);
      updateShowcaseImageLibrary(transformedImages);
      setShowcaseImagesIds((prev) => [
        ...prev,
        ...imageList.map(({ id }) => id),
      ]);

      dispatch(updateIILibraryAction(transformedImages));
    } catch (e) {
      errorToastr('Error', e.message);
    } finally {
      setLoading(false);
    }
  }, [
    createCancelToken,
    dispatch,
    hasMore,
    imagesSearchParams,
    loading,
    showcaseImagesIds.length,
    updateImagesSearchParams,
    updateShowcaseImageLibrary,
  ]);

  const loadLookBoards = useCallback(async () => {
    if (!hasLookBoardsMore || loadingLookBoards) return;

    try {
      setLookBoardsLoading(true);
      const cancelToken = createCancelToken();
      const { result: lookBoards } = await lookBoardService.getLookBoards(
        showcaseLookBoardsSearchParams,
        {
          cancelToken,
        }
      );
      const lookBoardsMap = transformArrayToMap(lookBoards);

      setShowcaseLookBoardsSearchParams((prevState) => ({
        ...prevState,
        offset: prevState.offset + lookBoards.length,
      }));

      setHasLookBoardsMore(
        showcaseLookBoardsSearchParams.limit === lookBoards.length
      );

      dispatch(updateLookboardsLibraryAction(lookBoardsMap));

      setShowcaseLookBoardIds((prev) => [
        ...prev,
        ...lookBoards.map(({ id }) => id),
      ]);
    } catch (e) {
      errorToastr('Error', e.message);
    } finally {
      setLookBoardsLoading(false);
    }
  }, [
    createCancelToken,
    dispatch,
    hasLookBoardsMore,
    loadingLookBoards,
    showcaseLookBoardsSearchParams,
  ]);

  const imagesToShow = useMemo(
    () =>
      showcaseImagesIds.filter((id) => !showcase?.featuredImages.includes(id)),
    [showcase, showcaseImagesIds]
  );
  const lookBoardsToShow = useMemo(
    () =>
      showcaseLookBoardIds.filter(
        (id) => !showcase?.featuredLookBoards.includes(id)
      ),
    [showcase, showcaseLookBoardIds]
  );

  useEffect(() => {
    (async () => {
      try {
        await loadLookBoards();
      } catch (e) {
        errorToastr('Error', e.message);
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await loadFeaturedImages();
      } catch (e) {
        errorToastr('Error', e.message);
      }
    })();
    // eslint-disable-next-line
  }, [
    imagesSearchParams.search,
    imagesSearchParams.colors,
    imagesSearchParams.styles,
    imagesSearchParams.subStyles,
    imagesSearchParams.roomTypes,
    imagesSearchParams.itemClasses,
  ]);

  useEffect(() => {
    if (scrollBarRootNode) {
      setInitialScrollableDatabaseHeight(scrollBarRootNode?.offsetHeight);
    }
  }, [activeTab, scrollBarRootNode, showcaseImagesIds, scrollBarRootRef]);

  const handleMoveToCTL = useCallback(
    (id) => {
      dispatch(selectCTLImageAction(id));
      history.push(`${routesByName.curateTheLook.canvas}`);
    },
    [dispatch, history]
  );

  return (
    <div className={classes.rightDragPanel}>
      <div>
        <h3 className="text-sm font-semi-bold mb-1 text-uppercase mb-4">
          My added images & look boards
        </h3>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <Button
            onClick={() =>
              dispatch(setShowcaseActiveTab(dropItemType.inspirationImages))
            }
            variant={
              activeTab === dropItemType.inspirationImages
                ? 'contained'
                : 'outlined'
            }
            size="xs"
            className="text-nowrap mr-2"
          >
            My Added Images
          </Button>
          <Button
            onClick={() =>
              dispatch(setShowcaseActiveTab(dropItemType.lookBoards))
            }
            variant={
              activeTab === dropItemType.lookBoards ? 'contained' : 'outlined'
            }
            size="xs"
          >
            My Look Boards
          </Button>
        </div>
        {activeTab === dropItemType.inspirationImages && (
          <>
            <div className="text-xs font-italic py-1 mb-1">
              Use filters for Best Results
            </div>
            <div className="d-flex mb-2">
              <div className="flex-fill">
                <SearchInput
                  inputRef={inputRef}
                  value={imagesSearchParams.search}
                  placeholder="Search by keywords"
                  onChange={handleChangeSearchQuery}
                  onCrossClick={handleCrossClick}
                />
              </div>

              <IconButton
                variant="inverted-white"
                onClick={handleOpenFiltersModal}
                className="ml-1"
              >
                <SpriteIcon name="filters" size="sm" />
              </IconButton>
            </div>
          </>
        )}
      </div>

      <div className={classes.database} ref={scrollBarRootRef}>
        {initialScrollableDatabaseHeight && (
          <>
            {activeTab === dropItemType.inspirationImages ? (
              <ScrollableDatabase
                initialHeight={initialScrollableDatabaseHeight}
                setInitialHeight={setInitialScrollableDatabaseHeight}
                scrollBarRef={scrollBarRef}
                loading={loading}
                hasMore={hasMore}
                loadMore={loadMoreFeaturedImages}
              >
                <Droppable
                  type={dropItemType.inspirationImages}
                  droppableId={dropItemType.inspirationImages}
                  isDropDisabled
                >
                  {(provided) => (
                    <div ref={provided.innerRef}>
                      {imagesToShow.map((id, index) => (
                        <Draggable
                          key={id}
                          draggableId={`${dropItemType.inspirationImages}_${id}`}
                          index={index}
                        >
                          {(
                            { draggableProps, dragHandleProps, innerRef },
                            snapshot
                          ) => (
                            <div
                              {...draggableProps}
                              {...dragHandleProps}
                              className={clsx(
                                snapshot.isDragging && classes.preview
                              )}
                              ref={innerRef}
                            >
                              <div className={clsx(classes.dragImageRoot)}>
                                <div className={classes.dragImage}>
                                  <img
                                    alt=""
                                    draggable="false"
                                    src={buildResourceUrl(
                                      inspirationImageUrl.medium,
                                      showcaseImagesLibrary[id].media.userId,
                                      showcaseImagesLibrary[id].media.hash
                                    )}
                                  />
                                </div>
                                <Button
                                  className={`${classes.dragImageCurate} mr-1`}
                                  size="custom"
                                  color="default"
                                  onClick={() => handleMoveToCTL(id)}
                                >
                                  <SpriteIcon
                                    name="living-room"
                                    size="sm"
                                    className="mr-1"
                                  />
                                  <span>Curate</span>
                                </Button>
                                <div className={classes.selectedImageInfo}>
                                  <span
                                    className={clsx(
                                      classes.selectedImageInfoTitle,
                                      'text-ellipsis'
                                    )}
                                  >
                                    {showcaseImagesLibrary[id].title}
                                  </span>
                                  <div
                                    className={
                                      classes.selectedImageInfoSubTitle
                                    }
                                  >
                                    {showcaseImagesLibrary[id].imageSource}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </ScrollableDatabase>
            ) : (
              <ScrollableDatabase
                initialHeight={initialScrollableDatabaseHeight}
                setInitialHeight={setInitialScrollableDatabaseHeight}
                scrollBarRef={scrollBarRef}
                loading={loadingLookBoards}
                hasMore={hasLookBoardsMore}
                loadMore={loadLookBoards}
              >
                <Droppable
                  type={dropItemType.lookBoards}
                  droppableId={dropItemType.lookBoards}
                  isDropDisabled
                >
                  {(provided) => (
                    <div ref={provided.innerRef}>
                      {lookBoardsToShow.map((id, index) => (
                        <Draggable
                          key={id}
                          draggableId={`${dropItemType.lookBoards}_${id}`}
                          index={index}
                        >
                          {({ draggableProps, dragHandleProps, innerRef }) => (
                            <div
                              {...draggableProps}
                              {...dragHandleProps}
                              ref={innerRef}
                            >
                              <div className={classes.dragImageRoot}>
                                <ShowcaseLookBoardItem
                                  lookBoard={showcaseLookBoardsLibrary[id]}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </ScrollableDatabase>
            )}
          </>
        )}
      </div>

      <FiltersModal
        showQuickFilter
        disabledFilters={[
          filterTabKeys.vibes,
          filterTabKeys.itemClasses,
          filterTabKeys.videos,
          filterTabKeys.metaTags,
          filterTabKeys.prices,
          filterTabKeys.shapes,
          filterTabKeys.materials,
        ]}
        open={filtersModalOpen}
        onClose={handleCloseFiltersModal}
        imageType={imagesSearchParams.type}
        filterValues={imagesSearchParams}
        onReset={resetImagesSearchParams}
        onApply={updateImagesSearchParams}
      />
    </div>
  );
};

export default RightDragPanel;
