import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import errorToastr from 'libs/toastr/errorToastr';
import currentUserService from 'modules/currentUser/currentUserService';
import ImageLink from 'components/imageThumbnails/ImageLink/ImageLink';
import buildResourceUrl from 'utils/buildResourceUrl';
import {
  getInspirationImgUrl,
  getUserAvatarThumbUrl,
} from 'modules/app/store/selectors';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import findObjectById from 'utils/findObjectById';
import BasicAvatar from 'components/ui/BasicAvatar';
import Button from 'components/ui/Button/Button';
import useFollowings from 'hooks/useFollowings';
import ConfirmModal from 'components/modals/ConfirmModal';
import useCancelToken from 'hooks/useCancelToken';
import { imageSourceKeys } from 'constants/inspirationImageSearchParams';
import { routesByName } from 'constants/routes';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';
import inspirationImageService from './inspirationImageService';
import classes from './InspirationImageDetails.module.scss';
import SimilarImageItem from '../curateTheLook/createLookBoard/components/SimilarImageItem/SimilarImageItem';

const InspirationImageDetails = () => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageUser, setCurrentImageUser] = useState(null);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [similarImages, setSimilarImages] = useState(false);
  const [curatedLookBoard, setCuratedLookBoard] = useState(null);
  const [createCancelTokenForImages] = useCancelToken();

  const { imageId } = useParams();

  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const currentUser = useSelector((state) => state.auth.user);

  const { colors, roomTypes, itemClasses, styles } = useSelector(
    (state) => state.app.enums
  );
  const config = useSelector((state) => state.app.config);

  const authorizedUserId = useMemo(() => currentUser?.id, [currentUser]);

  const [followed, handleFollowChange, confirmModalOpen] = useFollowings({
    followUserId: currentImageUser?.id,
    followUserName: `${currentImageUser?.firstName} ${currentImageUser?.lastName}`,
  });
  const userAvatarThumbUrl = useMemo(() => getUserAvatarThumbUrl(config), [
    config,
  ]);

  const isAprAndPublished = useMemo(
    () =>
      currentImage &&
      currentImage.approval === 'approved' &&
      currentImage.publish === 'publishedPlus',
    [currentImage]
  );

  useEffect(() => {
    (async () => {
      try {
        const cancelToken = createCancelTokenForImages();
        const { result: image } = await inspirationImageService.getImageById(
          imageId
        );
        const getUserPromise = currentUserService.getUserById(image.userId);

        const {
          styles: imageStyles,
          colors: imageColors,
          roomTypeId,
          itemClassId,
          subStyles,
        } = image;

        const preparedSubStyles = {};
        subStyles.forEach((subStyle) => {
          preparedSubStyles[subStyle] = 1;
        });

        const filterParams = {
          styles: imageStyles,
          colors: imageColors,
          ...(roomTypeId ? { roomTypes: [roomTypeId] } : {}),
          ...(itemClassId ? { itemClasses: [itemClassId] } : {}),
          subStyles: preparedSubStyles,
          source: imageSourceKeys.all,
          onlyApproved: true,
        };
        const [
          library,
          result,
        ] = await inspirationImageService.getSimilarImages(
          { ...filterParams },
          {
            cancelToken,
          }
        );
        const filteredRes = result.filter((item) => item.id !== image.id);
        dispatch(updateIILibraryAction(library));

        const user = await getUserPromise;
        const {
          result: { lookBoards },
        } = await inspirationImageService.getLookBoardDetails(image.id);
        if (lookBoards.length) {
          setCuratedLookBoard(lookBoards[0]);
        }
        setSimilarImages(filteredRes);
        setCurrentImage(image);
        setCurrentImageUser(user);
        setLoading(false);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    })();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    // eslint-disable-next-line
  }, [imageId]);

  const handleImgModalOpen = useCallback(() => {
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const imgUrl = useMemo(() => {
    if (!currentImage) {
      return null;
    }

    const {
      media: { userId, hash },
    } = currentImage;

    return buildResourceUrl(inspirationImageUrl.medium, userId, hash);
  }, [currentImage, inspirationImageUrl]);

  const goBack = useCallback(() => {
    history.push(routesByName.getTheLook.index);
  }, [history]);

  const imageStyles = useMemo(() => {
    if (!currentImage) {
      return [];
    }
    return currentImage.styles.map(
      (styleId) => findObjectById(styleId, styles)?.name
    );
  }, [currentImage, styles]);

  const roomTypeName = useMemo(() => {
    if (!currentImage) {
      return null;
    }
    return roomTypes[currentImage.roomTypeId]?.name;
  }, [currentImage, roomTypes]);

  const itemsTypeName = useMemo(() => {
    if (!currentImage) {
      return [];
    }
    return currentImage.itemTypeIds.map(
      (typeId) => findObjectById(typeId, itemClasses)?.name
    );
  }, [currentImage, itemClasses]);

  const imageColors = useMemo(() => {
    if (!currentImage) {
      return [];
    }
    return currentImage.colors.map((colorId) => colors[colorId]);
  }, [currentImage, colors]);

  const avatarUrl = useMemo(() => {
    if (currentImageUser && currentImageUser.avatar) {
      const {
        userId: currentUserId,
        hash: avatarHash,
      } = currentImageUser.avatar;
      return buildResourceUrl(userAvatarThumbUrl, currentUserId, avatarHash);
    }
    return null;
  }, [currentImageUser, userAvatarThumbUrl]);

  const handleSelectStyle = (id, slug) => {
    history.push(routesByName.inspirationImage.details(id, slug));
  };

  const handleGetTheLook = () => {
    history.push(
      routesByName.getTheLook.details(currentImage?.id, currentImage?.slug)
    );
  };

  const handleViewGetTheLook = () => {
    history.push(
      routesByName.getTheLook.details(currentImage?.id, currentImage?.slug)
    );
  };

  return !loading ? (
    <>
      <div className={classes.root}>
        <div className="d-flex align-items-center mb-3 mt-3">
          <IconButton variant="inverted-white" size="sm" onClick={goBack}>
            <SpriteIcon name="left-arrow" size="sm" />
          </IconButton>
          <span className="text-sm ml-1">Back</span>
        </div>
        <div className={clsx(!matchesMediaQuery && 'background-white mb-5')}>
          <div
            className={clsx(
              'd-flex',
              matchesMediaQuery && 'flex-column align-items-center'
            )}
          >
            <div className={classes.imageSection}>
              <img alt="" draggable="false" src={imgUrl} />
              <IconButton
                className={clsx(classes.selectedImageZoom)}
                color="secondary"
                size="sm"
                onClick={handleImgModalOpen}
              >
                <SpriteIcon name="loupe" size="sm" />
              </IconButton>
            </div>
            <div className={classes.infoSection}>
              {currentImage.source_url ? (
                <div
                  className={clsx(
                    classes.selectedImageLink,
                    'text-ellipsis',
                    'mb-3'
                  )}
                >
                  <ImageLink url={currentImage.source_url} color="grey" />
                </div>
              ) : null}

              <h3 className={classes.imageTitle}>{currentImage.title}</h3>
              <h2 className={classes.imageSourceTitle}>
                {currentImage.image_source_name}
              </h2>
              {currentImage.description && (
                <p className={`${classes.lookBoardDescription} mb-3`}>
                  {currentImage.description}
                </p>
              )}
              <div className={`${classes.bordered} text-sm mb-3`}>
                <div className="d-flex mb-2">
                  <span className="font-semi-bold mr-2">Styles:</span>
                  <span className="font-italic">{imageStyles.join(', ')}</span>
                </div>
                <div className="d-flex mb-2">
                  <span className="font-semi-bold mr-2">Room Type:</span>
                  <span className="font-italic">{roomTypeName}</span>
                </div>
                <div className="d-flex mb-2">
                  <span className="font-semi-bold mr-2">Colors:</span>
                  <div className="d-flex">
                    {imageColors.map(({ id: colorId, name, code }) => (
                      <div
                        key={colorId}
                        className={classes.colorPoint}
                        title={name}
                        style={{ background: code }}
                      />
                    ))}
                  </div>
                </div>
                {itemsTypeName.length ? (
                  <div className="d-flex mb-2">
                    <span className="font-semi-bold mr-2">Items:</span>
                    <span className="font-italic">
                      {itemsTypeName.join(', ')}
                    </span>
                  </div>
                ) : null}
              </div>
              {currentImageUser && (
                <div className="d-flex align-items-center mb-3">
                  <div className="flex-fill d-flex align-items-center text-ellipsis mr-2">
                    <BasicAvatar
                      firstName={currentImageUser.firstName}
                      lastName={currentImageUser.lastName}
                      src={avatarUrl}
                    />
                    <p className="text-sm ml-1 text-ellipsis font-semi-bold">
                      {currentImageUser.displayName ??
                        `${currentImageUser.firstName} ${currentImageUser.lastName}`}
                    </p>
                  </div>
                  {currentImageUser.id !== authorizedUserId && (
                    <Button
                      inline
                      color="secondary"
                      variant={followed ? 'contained' : 'outlined'}
                      size="sm"
                      onClick={handleFollowChange}
                    >
                      {followed ? 'Following' : 'Follow'}
                    </Button>
                  )}
                </div>
              )}
              {isAprAndPublished && (
                <Button
                  inline
                  color="primary"
                  variant="outlined"
                  size="sm"
                  onClick={handleGetTheLook}
                >
                  Get The Look
                </Button>
              )}
            </div>
          </div>
        </div>
        <>
          {!!similarImages.length && (
            <h3 className={`${classes.similarImageTitle} mb-3`}>
              Similar Images
            </h3>
          )}
          <div className={classes.similarImagesWrapper}>
            {similarImages.map((image) => (
              <div key={image.id} className={classes.similarImageItem}>
                <SimilarImageItem
                  id={image.id}
                  userId={image.media.userId}
                  hash={image.media.hash}
                  onSelectImage={handleSelectStyle}
                  title={image.title}
                  imageSource={image.image_source_name}
                  sourceUrl={image.source_url}
                  publish={image.publish}
                  slug={image.slug}
                />
              </div>
            ))}
          </div>
        </>
      </div>
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgUrl}
      />
      <ConfirmModal
        onClose={handleFollowChange}
        open={confirmModalOpen}
        title="Are you sure you want to unfollow user?"
      />
    </>
  ) : (
    <div className="text-center p-5">Loading...</div>
  );
};

export default InspirationImageDetails;
