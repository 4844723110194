import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash-es/groupBy';
import { useSelector } from 'react-redux';
import ProductsGroup from 'modules/getTheLook/components/ProductsGroup/ProductsGroup';
import SpriteIcon from 'components/ui/SpriteIcon';
import ProductItem from 'modules/getTheLook/components/ProductItem';
import customEvent from 'utils/customEvent';
import {
  SET_INITIAL_SCROLLER_HEIGHT,
  UPDATE_SCROLLER_HEIGHT,
} from 'constants/customEventNames';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import useLoadMoreCallback from 'hooks/useLoadMoreCallback';
import RecommendationsProductItem from 'modules/requestTheLook/recommendations/components/RecommendationsProductItem/RecommendationsProductItem';
import classes from 'modules/curateTheLook/createLookBoard/components/SelectedImageBlock/SelectedImageBlock.module.scss';
import findObjectById from '../../../utils/findObjectById';
import { productsSortKeys } from '../constants';
import { sortByAdminRating } from '../../../utils/sort';

const limit = 4;

const ProductsStream = ({
  products,
  users,
  onToggleLikeProduct,
  onRateProduct,
  withRating,
}) => {
  const itemClasses = useSelector((state) => state.app.enums.itemClasses);

  const [selectedGroup, setSelectedGroup] = useState(null);

  const { mixMatchSort } = useSelector((state) => state.getTheLook);

  const sortItems = useCallback(
    (productGroups) => {
      const res = {};
      const filteredGroups = {};

      Object.keys(productGroups).forEach((key) => {
        const filter = productGroups[key].filter(
          (item) =>
            item.approval === 'approved' && item.publish !== 'notPublish'
        );
        filteredGroups[key] = filter.length ? filter : [];
      });

      switch (mixMatchSort) {
        case productsSortKeys.bestMatch: {
          Object.keys(filteredGroups).forEach((key) => {
            res[key] = filteredGroups[key].sort(sortByAdminRating);
          });
          return res;
        }
        case productsSortKeys.mostLikes: {
          Object.keys(filteredGroups).forEach((key) => {
            res[key] = filteredGroups[key].sort(
              (a, b) => b.likesCount - a.likesCount
            );
          });
          return res;
        }
        case productsSortKeys.priceLowToHigh: {
          Object.keys(filteredGroups).forEach((key) => {
            res[key] = filteredGroups[key].sort((a, b) => a.price - b.price);
          });
          return res;
        }
        default: {
          return filteredGroups;
        }
      }
    },
    [mixMatchSort]
  );

  const productGroups = useMemo(() => {
    const groups = groupBy(Object.values(products), 'itemClassId');
    return Object.fromEntries(
      Object.entries(sortItems(groups)).filter(([, value]) => value.length > 0)
    );
  }, [products, sortItems]);

  const [hasMore, loading, visible, loadMore] = useLoadMoreCallback(
    limit,
    Object.keys(productGroups).length
  );

  const handleSelectGroup = useCallback((id) => {
    setSelectedGroup(id);
  }, []);

  const handleUnselectGroup = useCallback(() => {
    setSelectedGroup(null);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      customEvent.trigger(SET_INITIAL_SCROLLER_HEIGHT);
      customEvent.trigger(UPDATE_SCROLLER_HEIGHT);
    }, 0);
  }, [selectedGroup]);

  return (
    <CustomInfiniteScroll
      loadMore={loadMore}
      isLoading={loading}
      hasMore={hasMore}
      initialLoad={false}
    >
      <div className="row">
        {selectedGroup ? (
          <div className="col-12">
            <div
              className="d-flex align-items-center cursor-pointer mb-3"
              onClick={handleUnselectGroup}
            >
              <SpriteIcon
                name="left-arrow"
                size="sm"
                className="primary-color mr-1"
              />
              <span className={classes.goBackBtn}>
                Back To All Matching Items
              </span>
            </div>
            <div className="row">
              {productGroups[selectedGroup].map((product) => (
                <div key={product.id} className="col-4 px-1 pb-2">
                  {withRating ? (
                    <RecommendationsProductItem
                      product={product}
                      onToggleLike={onToggleLikeProduct}
                      onRate={onRateProduct}
                    />
                  ) : (
                    <ProductItem
                      product={{ ...product }}
                      user={users[product.userId]}
                      onToggleLike={onToggleLikeProduct}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          Object.keys(productGroups)
            .slice(0, visible)
            .map((itemClassId) => (
              <div className="col-12 mb-5" key={itemClassId}>
                <ProductsGroup
                  itemClassId={itemClassId}
                  groupName={findObjectById(itemClassId, itemClasses).name}
                  products={productGroups[itemClassId]}
                  users={users}
                  onToggleLikeProduct={onToggleLikeProduct}
                  onRateProduct={onRateProduct}
                  onSelectGroup={handleSelectGroup}
                  withRating={withRating}
                />
              </div>
            ))
        )}
      </div>
    </CustomInfiniteScroll>
  );
};

ProductsStream.propTypes = {
  products: PropTypes.shape({}).isRequired,
  users: PropTypes.shape({}).isRequired,
  onToggleLikeProduct: PropTypes.func.isRequired,
  onRateProduct: PropTypes.func,
  withRating: PropTypes.bool,
};

ProductsStream.defaultProps = {
  withRating: false,
  onRateProduct: () => {},
};

export default ProductsStream;
