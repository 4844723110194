import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Columns from 'react-columns';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import SpriteIcon from 'components/ui/SpriteIcon';
import errorToastr from 'libs/toastr/errorToastr';
import useCancelToken from 'hooks/useCancelToken';
import PropTypes from 'prop-types';
import Button from 'components/ui/Button/Button';
import clsx from 'clsx';
import { routesByName } from 'constants/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { unauthorizedLimit } from 'constants/inspirationImages';
import {
  imagePageTypes,
  imageSourceKeys,
} from 'constants/inspirationImageSearchParams';
import { ReactComponent as Preloader } from 'assets/icons/preloader.svg';
import classes from '../GetTheLook.module.scss';
import {
  loadFirstAction,
  loadMoreAction,
  toggleLikeLookBoardAction,
  updateSearchParamsAction,
  voteLookBoardAction,
} from '../store/actions';
import GalleryItem from './GalleryItem';
import LookBoardItem from './LookBoardItem';
import { getInspirationImgUrl } from '../../app/store/selectors';
import lookBoardService from '../../lookBoard/lookBoardService';
import {
  II_LIKE_LOOKBOARD_GTL,
  II_VOTE_LOOKBOARD_GTL,
} from '../../inspirationImage/store/constants';
import { getTheLookTabKeys } from '../constants';
import LookBoardPreviewModal from '../../../components/modals/LookBoardPreviewModal/LookBoardPreviewModal';
import GalleryMobileItem from './GalleryMobileItem';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../constants/mediaQueries';

const itemsPerPage = 16;
const MAX_ITEMS = 100;
const queries = [
  {
    columns: 2,
    query: 'min-width: 500px',
  },
  {
    columns: 2,
    query: 'min-width: 900px',
  },
  {
    columns: 3,
    query: 'min-width: 1220px',
  },
  {
    columns: 4,
    query: 'min-width: 1650px',
  },
];

const GalleryView = ({
  setStyleQuizModalOpen,
  showLikedImages,
  voteLookBoard,
  authenticated,
  childFunctionRef,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const {
    imageList: list,
    hasMore,
    loading,
    searchParams,
    filterValues,
    likedImages,
  } = useSelector((state) => state.getTheLook);
  const library = useSelector((state) => state.inspirationImage.library);
  const currentUser = useSelector((state) => state.auth.user);
  const [createCancelToken, isCancelled] = useCancelToken();
  const imageList = useMemo(() => {
    return list.map((id) => library[id]);
  }, [library, list]);

  const [imagesToShow, setImagesToShow] = useState(imageList);
  const [lookBoardForModal, setLookBoardForModal] = useState(null);

  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const GalleryItemComponent = matchesMediaQuery
    ? GalleryMobileItem
    : GalleryItem;

  useEffect(() => {
    setImagesToShow(showLikedImages ? likedImages : imageList);
  }, [showLikedImages, likedImages, imageList]);

  const handleLoadMore = useCallback(() => {
    if (!currentUser && searchParams.offset >= unauthorizedLimit) return;
    if (showLikedImages) return;
    if (searchParams.offset < MAX_ITEMS) dispatch(loadMoreAction(itemsPerPage));
  }, [currentUser, dispatch, searchParams.offset, showLikedImages]);

  const handleRetakeQuiz = useCallback(() => setStyleQuizModalOpen(true), [
    setStyleQuizModalOpen,
  ]);

  const handleRegisterClick = useCallback(() => {
    history.push(
      `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`,
      { title: 'REGISTER NOW' }
    );
  }, [history, pathname]);

  useEffect(() => {
    if (currentUser) {
      dispatch(
        updateSearchParamsAction({
          ...searchParams,
          source: imageSourceKeys.all,
          offset: 0,
          lbCount: null,
          pageType: imagePageTypes.getTheLook,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0 && searchParams.lbCount === null) {
        try {
          await dispatch(loadFirstAction(createCancelToken, itemsPerPage));
        } catch (e) {
          if (!isCancelled(e)) {
            errorToastr('Error', e.message);
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, [JSON.stringify(filterValues), JSON.stringify(searchParams)]);

  const handleVoteLookBoard = useCallback(
    async (lookBoardId, voteValue, imageId) => {
      try {
        if (showLikedImages) {
          await voteLookBoard(imageId, lookBoardId, voteValue);
        } else {
          await lookBoardService.voteHandler(lookBoardId, voteValue);
          dispatch({
            type: II_VOTE_LOOKBOARD_GTL,
            payload: { imageId },
          });
        }
      } catch (e) {
        history.push(`?${routesByName.auth.key}=${routesByName.auth.signIn}`);
        errorToastr('Error', e.message);
      }
    },
    [dispatch, history, showLikedImages, voteLookBoard]
  );
  const handleToggleLikeLookBoard = useCallback(
    async (lookBoardId, likeStatus, imageId) => {
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      try {
        await lookBoardService.toggleLike(lookBoardId, likeStatus);
        dispatch({
          type: II_LIKE_LOOKBOARD_GTL,
          payload: { imageId, likeStatus },
        });
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [authenticated, dispatch, history, pathname]
  );

  /*  const lookBoardToShow = useMemo(() => {
    if (showLikedImages) return likedLookBoards;
    return Object.keys(lookBoardsData).length
      ? list.map((id) => lookBoardsData[id]?.lookBoards[0])
      : [];
  }, [likedLookBoards, list, lookBoardsData, showLikedImages]); */

  const combinedToShowArr = useMemo(() => {
    return [...imagesToShow];
  }, [imagesToShow]);

  const combinedLikedArr = useMemo(() => {
    return imagesToShow.map((image) => ({
      type: 'galleryItem',
      image,
    }));
  }, [imagesToShow]);

  const bottomGraphicButtonsConfig = [
    {
      title: 'Change Filters',
      icon: 'filters',
      route: routesByName.getTheLook.index,
      action: () => {
        childFunctionRef.current();
      },
    },
    {
      title: 'Request the Look',
      icon: 'exchange',
      route: routesByName.requestTheLook.index,
    },
    {
      title: 'View Showcases',
      icon: 'followers',
      route: routesByName.getTheLook.ambassadors.index,
    },
    {
      title: 'Get the Pin',
      icon: 'pin',
      route: routesByName.getThePin,
    },
  ];

  const onOpenLookBoardModal = useCallback(
    (id) => {
      const lookBoard = imageList.find((obj) => obj.id === id);
      setLookBoardForModal(lookBoard);
    },
    [imageList]
  );

  const closeLookBoardForModal = useCallback(() => {
    setLookBoardForModal(null);
  }, []);

  if (loading && searchParams.offset === 0)
    return (
      <div className={classes.loader}>
        <Preloader />
      </div>
    );

  return (
    <>
      <CustomInfiniteScroll
        isLoading={loading}
        hasMore={hasMore}
        loadMore={handleLoadMore}
        initialLoad={false}
        threshold={500}
      >
        {combinedToShowArr.length ? (
          <div className={classes.row}>
            {showLikedImages ? (
              <Columns queries={queries} className="overflow-hidden">
                {combinedLikedArr.map((card) => {
                  if (card.type === 'galleryItem') {
                    return (
                      <GalleryItemComponent
                        key={card.image.id}
                        image={card.image}
                        likedImages
                      />
                    );
                  }
                  if (card.type === 'lookBoardItem') {
                    return (
                      <div className="p-1">
                        <LookBoardItem
                          key={card.lookBoard.id}
                          lookBoard={card.lookBoard}
                          user={card.user}
                          products={card.products}
                          onToggleLike={handleToggleLikeLookBoard}
                          onVote={handleVoteLookBoard}
                          hideLikeVote
                          isGalleryItem
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </Columns>
            ) : (
              <Columns queries={queries} className="overflow-hidden">
                {imageList.map((card) => {
                  if (card.type === 'lookBoardItem') {
                    return (
                      <LookBoardItem
                        key={card.id}
                        lookBoard={card}
                        user={card.user}
                        products={card.products}
                        onToggleLike={handleToggleLikeLookBoard}
                        onVote={handleVoteLookBoard}
                        isGalleryView
                        isGalleryItem
                        onOpenModal={onOpenLookBoardModal}
                      />
                    );
                  }
                  return <GalleryItemComponent key={card.id} image={card} />;
                })}
              </Columns>
            )}
            {!currentUser && searchParams.offset >= unauthorizedLimit && (
              <div className="font-weight-normal d-flex justify-content-center pb-3 mt-2">
                <Button
                  size="md"
                  className={clsx('w-auto mx-auto', classes.btn)}
                  onClick={handleRegisterClick}
                >
                  Register Now
                </Button>
              </div>
            )}
          </div>
        ) : (
          <>
            {loading ? (
              <div className="text-center pt-3">
                <Preloader />
              </div>
            ) : (
              <div className="d-flex w-100 pb-3 mt-3">
                <div className={classes.leftPanelWrapper} />
                <div
                  className={clsx(
                    'flex-fill',
                    'd-flex',
                    'flex-column',
                    'justify-content-center',
                    'align-items-center',
                    classes.mainContainer,
                    classes.emptyContainer
                  )}
                >
                  <h2 className={classes.emptyTitle}>NO RESULTS AVAILABLE</h2>
                  <Button
                    onClick={handleRetakeQuiz}
                    variant="outlined"
                    className="d-block w-auto"
                    size="lg"
                  >
                    Retake the Style Quiz
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </CustomInfiniteScroll>
      {!loading && activeTab === getTheLookTabKeys.galleryView && (
        <div className={classes.bottomGraphic}>
          <h5 className={classes.bottomGraphic__subTitle}>End of Results</h5>
          <h2 className={classes.bottomGraphic__title}>
            Get more looks in styles you love
          </h2>
          <div className={classes.bottomGraphic__btnWrapper}>
            {bottomGraphicButtonsConfig.map(
              ({ title, icon, route, action }) => (
                <Button
                  key={title}
                  variant="outlined"
                  inline
                  onClick={action || (() => history.push(route))}
                  className={classes.bottomGraphic__btn}
                  size="custom"
                  color="primary"
                >
                  <div className={classes.btnIconWrapper}>
                    <SpriteIcon name={icon} size="md" />
                  </div>
                  {title}
                </Button>
              )
            )}
          </div>
        </div>
      )}
      <LookBoardPreviewModal
        open={!!lookBoardForModal}
        onClose={closeLookBoardForModal}
        lookBoard={lookBoardForModal}
      />
    </>
  );
};
GalleryView.propTypes = {
  setStyleQuizModalOpen: PropTypes.func.isRequired,
  showLikedImages: PropTypes.bool.isRequired,
  voteLookBoard: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  childFunctionRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
};
const mapStateToProps = ({
  app: { config },
  getTheLook: { activeTab, searchParams },
  auth: { user },
}) => ({
  activeTab,
  searchParams,
  inspirationImageUrl: getInspirationImgUrl(config),
  authenticated: Boolean(user),
});

const mapDispatchToProps = {
  toggleLikeLookBoard: toggleLikeLookBoardAction,
  voteLookBoard: voteLookBoardAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(GalleryView);
