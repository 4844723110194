import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
  columnViewRadioOptions,
  stepTwoColumnViewKeys,
} from 'modules/curateTheLook/constants';
import ProductItem from 'modules/curateTheLook/createLookBoard/components/ProductItem/ProductItem';
import CustomRadioGroup from 'modules/curateTheLook/createLookBoard/components/CustomRadioGroup';

const ProductsDatabase = ({ loading, hasMore, loadMore, scrollerHeight }) => {
  const dndState = useSelector((state) => state.curateTheLook.dndState);
  const library = useSelector((state) => state.product.library);
  const productsColumnId = useMemo(() => dndState.productList.id, [dndState]);
  const productList = useMemo(
    () =>
      dndState.productList.productIds
        .map((id) => library[id])
        .filter((item) => !!item),
    [dndState, library]
  );

  const [columnView, setColumnView] = useState(stepTwoColumnViewKeys.double);

  const handleChaneColumnView = useCallback(({ target: { value } }) => {
    setColumnView(value);
  }, []);

  function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`,
    };
  }

  return (
    <>
      <CustomRadioGroup
        controls={columnViewRadioOptions}
        value={columnView}
        onChange={handleChaneColumnView}
      />
      <p className="text-xs font-italic mb-2">
        Drag & Drop items into the template
      </p>
      <CustomInfiniteScroll
        parentHeight={scrollerHeight}
        isLoading={loading}
        hasMore={hasMore}
        loadMore={loadMore}
        initialLoad={false}
        useWindow={false}
      >
        <Droppable droppableId={productsColumnId} isDropDisabled>
          {(provided) => (
            <div ref={provided.innerRef} className="row">
              {productList.map(
                (
                  {
                    id,
                    isLiked,
                    url,
                    media: { userId, hash },
                    isAffiliate,
                    price,
                  },
                  index
                ) => (
                  <div
                    key={id}
                    className={clsx('mb-2', {
                      'col-6': columnView === stepTwoColumnViewKeys.double,
                      'col-12': columnView === stepTwoColumnViewKeys.single,
                    })}
                  >
                    <Draggable draggableId={String(id)} index={index}>
                      {(
                        { draggableProps, dragHandleProps, innerRef },
                        snapshot
                      ) => (
                        <>
                          <div
                            {...draggableProps}
                            {...dragHandleProps}
                            style={getStyle(draggableProps.style, snapshot)}
                            ref={innerRef}
                          >
                            <ProductItem
                              id={id}
                              url={url}
                              userId={userId}
                              hash={hash}
                              price={price}
                              isLiked={isLiked}
                              isDragging={snapshot.isDragging}
                              isAffiliate={isAffiliate}
                            />
                          </div>
                        </>
                      )}
                    </Draggable>
                  </div>
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </CustomInfiniteScroll>
    </>
  );
};

ProductsDatabase.propTypes = {
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  scrollerHeight: PropTypes.number.isRequired,
};

export default ProductsDatabase;
