import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { routesByName } from 'constants/routes';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import {
  toggleHowItWorksModalAction,
  toggleVerificationModalAction,
} from 'modules/layout/store/actions';
import RequestModal from 'modules/ambassadors/components/RequestModal/RequestModal';
import RequestSentModal from 'modules/ambassadors/components/RequestSentModal/RequestSentModal';
import ambassadorService from 'modules/ambassadors/ambassadorService';
import errorToastr from 'libs/toastr/errorToastr';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import clsx from 'clsx';
import pinterest from 'assets/img/pinterestIcon.png';
import classes from './Footer.module.scss';

const blogLink = 'https://the-curated-life.blog/';

// TODO: Replace links paths to actual routes

const Footer = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [requestSentModalOpen, setRequestSentModalOpen] = useState(false);

  const currentYear = useMemo(() => moment().format('YYYY'), []);

  const history = useHistory();
  const { pathname } = useLocation();

  const hideFooter = useMemo(
    () => pathname === routesByName.dashboard.messages,
    [pathname]
  );

  const handleInvitationRequest = useCallback(() => {
    if (!user) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!user?.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }

    if (user?.ambassador) {
      history.push(routesByName.dashboard.ambassadorManagement);
      return;
    }

    if (!user?.ambassador) {
      setRequestModalOpen(true);
    }
  }, [user, history, pathname, dispatch]);

  const handleCloseRequestSentModal = useCallback(() => {
    setRequestSentModalOpen(false);
  }, []);

  const handleCloseRequestModal = useCallback(() => {
    setRequestModalOpen(false);
  }, []);

  const handleSendRequest = useCallback(async (values) => {
    try {
      await ambassadorService.requestAccess(values);
      setRequestModalOpen(false);
      setRequestSentModalOpen(true);
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, []);
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const noMatchesMediaQueryWrapper = useCallback(
    (func) => {
      if (matchesMediaQuery) {
        history.push(routesByName.unavailableTool);
        return;
      }
      func();
    },
    [matchesMediaQuery, history]
  );

  return hideFooter ? null : (
    <div className={classes.root} id="footer">
      <div className="d-flex">
        <div className="d-none d-md-flex align-items-end">
          <div className={classes.column}>
            <div className="font-semi-bold text-uppercase primary-color text-lg mb-2">
              Company
            </div>
            <ul className={classes.list}>
              <li className="mb-1">
                <Link
                  to={
                    !matchesMediaQuery
                      ? routesByName.about
                      : routesByName.unavailableTool
                  }
                >
                  About Us
                </Link>
              </li>
              <li className="mb-1">
                <Link
                  to={
                    !matchesMediaQuery
                      ? routesByName.faqs
                      : routesByName.unavailableTool
                  }
                >
                  Pin the Look FAQs
                </Link>
              </li>
              <li className="mb-1">
                <Link to={routesByName.contact}>Contact Us</Link>
              </li>
              <li className="mb-1">
                <a href={blogLink}>Blog</a>
              </li>
              <li className="cursor-pointer mb-1">
                <Link to={routesByName.terms}>Terms</Link>
              </li>
              <li className="cursor-pointer mb-1">
                <Link to={routesByName.privacy}>Privacy</Link>
              </li>
              <li className="cursor-pointer">
                <Link to={routesByName.community}>Community Guidelines</Link>
              </li>
            </ul>
          </div>
          <div className={classes.horizontalDivider} />
          <div className={classes.column}>
            <div className="font-semi-bold text-uppercase primary-color text-lg mb-2">
              How It Works
            </div>
            <ul className={classes.list}>
              <li
                className="mb-1 cursor-pointer"
                onClick={() => {
                  noMatchesMediaQueryWrapper(() => {
                    dispatch(toggleHowItWorksModalAction(true));
                  });
                }}
              >
                Overview
              </li>
              <li className="mb-1">
                <Link to={routesByName.getTheLook.index}>Get the Look</Link>
              </li>
              <li className="mb-1">
                <Link to={routesByName.requestTheLook.index}>
                  Request the Look
                </Link>
              </li>
              <li className="mb-1">
                <Link to={routesByName.curateTheLook.index}>
                  Curate the Look
                </Link>
              </li>
              <li className="mb-1">
                <Link to={routesByName.getThePin}>Get The Pin</Link>
              </li>
              <li>
                <Link to={routesByName.styleQuiz}>Style Quiz</Link>
              </li>
            </ul>
          </div>
          <div className={classes.horizontalDivider} />
          <div className={classes.column}>
            <div className="font-semi-bold text-uppercase primary-color text-lg mb-2">
              Ambassadors
            </div>
            <ul className={classes.list}>
              <li className="mb-1">
                <Link to={routesByName.ambassador.index}>Overview</Link>
              </li>
              <li
                className="mb-1 cursor-pointer"
                onClick={handleInvitationRequest}
              >
                Ambassador Application
              </li>
              <li>
                <Link to={routesByName.ambassador.faqs}>Ambassador FAQs</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex-fill d-flex align-items-center justify-content-center justify-content-md-end">
          <div className={classes.horizontalDivider} />
          <div className="d-flex flex-column text-center text-md-left">
            <div className={clsx('d-flex', classes.socialWrapper)}>
              <span className={`${classes.socialLinksTitle} pt-1`}>
                Keep Connected
              </span>
              <div className="d-flex pt-1">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/PintheLookDecor?mibextid=kFxxJD"
                  className="mr-2"
                >
                  <IconButton size="sm" className={classes.socialLinkBtn}>
                    <SpriteIcon name="facebook" size="sm" />
                  </IconButton>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/pinthelooklover?igsh=MzRlODBiNWFlZA=="
                  className="mr-2"
                >
                  <IconButton size="sm" className={classes.socialLinkBtn}>
                    <SpriteIcon name="instagram" size="sm" />
                  </IconButton>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.pinterest.com/pinthelooklover/"
                >
                  <IconButton size="sm" className={classes.socialLinkBtn}>
                    <img src={pinterest} alt="pinterest" />
                  </IconButton>
                </a>
              </div>
            </div>
            <div className="mt-2 font-italic font-semi-bold text-sm">
              Pin the Look 2022-{currentYear}
            </div>
          </div>
        </div>
      </div>
      <RequestModal
        open={requestModalOpen}
        onClose={handleCloseRequestModal}
        onSubmit={handleSendRequest}
      />
      <RequestSentModal
        open={requestSentModalOpen}
        onClose={handleCloseRequestSentModal}
      />
    </div>
  );
};

export default Footer;
