import React, { memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as Preloader } from 'assets/icons/preloader.svg';
import { connect, useDispatch, useSelector } from 'react-redux';
import Columns from 'react-columns';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import gtlClasses from '../../../../GetTheLook.module.scss';
import classes from './ProductList.module.scss';
import CustomInfiniteScroll from '../../../../../../components/CustomInfiniteScroll';
import productService from '../../../../../product/productService';
import { initialProductFilterValues } from '../../../../../curateTheLook/store/reducer';
import useCancelToken from '../../../../../../hooks/useCancelToken';
import ProductItem from '../../../ProductItem';
import { updateGTLProductSearchParamsAction } from '../../../../store/actions';
import { routesByName } from '../../../../../../constants/routes';
import errorToastr from '../../../../../../libs/toastr/errorToastr';

const queries = [
  {
    columns: 2,
    query: 'min-width: 500px',
  },
  {
    columns: 2,
    query: 'min-width: 900px',
  },
  {
    columns: 3,
    query: 'min-width: 1220px',
  },
  {
    columns: 4,
    query: 'min-width: 1650px',
  },
];

const ProductsList = ({ authenticated }) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [products, setProducts] = useState([]);

  const [createCancelToken, isCancelled] = useCancelToken();

  const enums = useSelector((state) => state.app.enums);
  const {
    productSearchParams,
    productFilterValues: filterValues,
  } = useSelector((state) => state.getTheLook);

  const loadProducts = useCallback(
    async (searchParams) => {
      if (!loading) {
        setLoading(true);
        const cancelToken = createCancelToken();

        const colors = Object.values(enums.colors)
          .filter((color) => filterValues.colors.includes(color.color_group_id))
          .map((color) => color.id);

        try {
          const list = await productService.getProducts(
            {
              ...searchParams,
              ...(searchParams.search.trim().length
                ? initialProductFilterValues
                : filterValues),
              ...(searchParams.search.trim().length ? {} : { colors }),
              pageType: 'gtl',
            },
            { cancelToken }
          );
          if (list.length === 0) {
            setHasMore(false);
          }
          dispatch(
            updateGTLProductSearchParamsAction({
              offset: searchParams.offset + list.length,
            })
          );
          if (searchParams.offset === 0) {
            setProducts([...list]);
          } else {
            setProducts([...products, ...list]);
          }
        } catch (e) {
          if (!isCancelled(e)) {
            console.log(e);
            errorToastr('Error', e.generalError);
          }
        } finally {
          setLoading(false);
        }
      }
    },
    [
      createCancelToken,
      dispatch,
      enums.colors,
      filterValues,
      isCancelled,
      loading,
      products,
    ]
  );

  const handleLoadMore = useCallback(() => {
    loadProducts(productSearchParams);
  }, [loadProducts, productSearchParams]);

  const handleToggleLikeProduct = useCallback(
    async (productId, likeStatus) => {
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      try {
        const copyProducts = [...products];
        await productService.toggleLike(productId, likeStatus);

        const currentProduct = copyProducts.find(({ id }) => id === productId);
        currentProduct.isLiked = likeStatus;
        setProducts([...copyProducts]);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [authenticated, history, pathname, products]
  );

  useEffect(() => {
    setProducts([]);
    setHasMore(true);
    loadProducts({ ...productSearchParams, offset: 0 }).then(() => {
      setIsInit(true);
    });
  }, []);

  useEffect(() => {
    if (isInit) {
      setProducts([]);
      setHasMore(true);
      loadProducts(productSearchParams).then(() => {});
    }
  }, [filterValues]);

  useEffect(() => {
    if (isInit) {
      setProducts([]);
      setHasMore(true);
      loadProducts(productSearchParams).then(() => {});
    }
  }, [productSearchParams.search]);

  return (
    <CustomInfiniteScroll
      isLoading={loading}
      hasMore={hasMore}
      loadMore={handleLoadMore}
      initialLoad={false}
      threshold={500}
      className={classes.wrapper}
    >
      {!!products.length && (
        <Columns queries={queries} className="overflow-hidden">
          {products.map((product) => (
            <div className={classes.itemContainer}>
              <ProductItem
                product={{ ...product, price: product.price * 100 }}
                onToggleLike={handleToggleLikeProduct}
              />
            </div>
          ))}
        </Columns>
      )}

      <>
        {loading && (
          <div className="text-center pt-3">
            <Preloader />
          </div>
        )}
        {!products.length && !loading && (
          <div className="d-flex w-100 pb-3 mt-3">
            <div className={gtlClasses.leftPanelWrapper} />
            <div
              className={clsx(
                'flex-fill',
                'd-flex',
                'flex-column',
                'justify-content-center',
                'align-items-center',
                gtlClasses.mainContainer,
                gtlClasses.emptyContainer
              )}
            >
              <h2 className={gtlClasses.emptyTitle}>NO RESULTS AVAILABLE</h2>
            </div>
          </div>
        )}
      </>
    </CustomInfiniteScroll>
  );
};
const mapStateToProps = ({ auth: { user } }) => ({
  authenticated: Boolean(user),
});

ProductsList.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(memo(ProductsList));
