import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  setAmbassadorsActiveTabAction,
  syncSelectedAmbassadorDataAction,
  toggleLikeAmbassadorLookBoardAction,
} from 'modules/getTheLook/ambassadorPage/store/actions';
import errorToastr from 'libs/toastr/errorToastr';
import LookBoardItem from 'modules/getTheLook/components/LookBoardItem';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'modules/getTheLook/GetTheLook.module.scss';
import AmbassadorCard from 'components/userThumbnails/AmbassadorCard/AmbassadorCard';
import { bannerTypeKeys } from 'modules/dashboard/showcase/constants';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getTheLookAmbassadorsTabKeys } from 'modules/getTheLook/ambassadorPage/config';
import NoLookBoardsModal from 'modules/getTheLook/ambassadorPage/components/NoLookBoardsModal/NoLookBoardsModal';
import Button from '../../../components/ui/Button/Button';
import { routesByName } from '../../../constants/routes';
import findObjectById from '../../../utils/findObjectById';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../constants/mediaQueries';

const DetailsAmbassadorView = () => {
  const dispatch = useDispatch();
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const {
    user = {},
    urlKey,
    id,
    userId,
    pageName,
    banner,
    featuredLookBoards,
  } = useSelector((state) => state.ambassadorsPage.selectedAmbassador || {});
  const styles = useSelector((state) => state.app.enums.styles);
  const currentAmbassadorStyles = user.styles?.map(
    (styleId) => findObjectById(styleId, styles)?.name
  );
  const { lookBoardsData } = useSelector((state) => state.ambassadorsPage);
  const { showcaseBanner, userAvatar } = useSelector(
    (state) => state.app.config.endpoints.media
  );
  const bannerUrlPlaceholder = showcaseBanner.small;
  const userAvatarThumbUrl = userAvatar.thumb;
  const { firstName, lastName, assignAmbassadorAt, badge, displayName } = user;

  useEffect(() => {
    (async () => {
      try {
        await dispatch(syncSelectedAmbassadorDataAction(id));
      } catch (e) {
        errorToastr(e.message);
      }
    })();
  }, [id, dispatch]);
  const handleGoToAmbassadorsShowcase = useCallback(() => {
    dispatch(setAmbassadorsActiveTabAction(getTheLookAmbassadorsTabKeys.main));
  }, [dispatch]);

  const handleToggleLikeLookBoard = useCallback(
    async (lookBoardId, likeStatus) => {
      try {
        await dispatch(
          toggleLikeAmbassadorLookBoardAction(lookBoardId, likeStatus)
        );
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [dispatch]
  );

  const bannerUrl = useMemo(() => {
    if (user) {
      const { media, type: bannerType } = banner || {};

      return bannerType === bannerTypeKeys.image && media
        ? buildResourceUrl(bannerUrlPlaceholder, media.userId, media.hash)
        : null;
    }
    return null;
  }, [banner, bannerUrlPlaceholder, user]);

  const avatarUrl = useMemo(() => {
    if (user.avatar) {
      const { userId: showcaseUserId, hash: avatarHash } = user.avatar;
      return buildResourceUrl(userAvatarThumbUrl, showcaseUserId, avatarHash);
    }
    return null;
  }, [user, userAvatarThumbUrl]);

  const handleViewShowcase = useCallback(async () => {
    window.open(routesByName.showcase.details(urlKey), '_blank');
  }, [urlKey]);

  const ambassadorCardProps = useMemo(
    () => ({
      badge,
      firstName,
      lastName,
      banner,
      bannerUrl,
      lookBoardsCount: user.lookBoards || 0,
      title: pageName,
      styles: currentAmbassadorStyles,
      avatarUrl,
      userId,
      urlKey,
      curatedLookBoards: featuredLookBoards?.length || 0,
      handleViewShowcase,
      assignAmbassadorAt,
      displayName,
    }),
    [
      badge,
      featuredLookBoards,
      user,
      firstName,
      lastName,
      banner,
      bannerUrl,
      pageName,
      currentAmbassadorStyles,
      avatarUrl,
      userId,
      urlKey,
      handleViewShowcase,
      assignAmbassadorAt,
      displayName,
    ]
  );

  return (
    <>
      <div className="flex-fill d-flex">
        {!matchesMediaQuery && (
          <div
            className={`${classes.leftPanelWrapper} pb-3`}
            style={{ marginTop: '-70px' }}
          >
            <div className="flex-fill d-flex flex-column">
              <div
                className="d-flex align-items-center cursor-pointer mb-3"
                onClick={handleGoToAmbassadorsShowcase}
              >
                <Button
                  variant="outlined"
                  className={`${classes.goBackBtn} mt-1`}
                >
                  <SpriteIcon
                    name="left-arrow"
                    size="sm"
                    className="primary-color mr-1"
                  />
                  <span>Ambassador showcase Profiles</span>
                </Button>
              </div>
              <AmbassadorCard {...ambassadorCardProps} showMoreInfo />
            </div>
          </div>
        )}
        <div className="flex-fill">
          <div className={classes.mainContainer}>
            {!featuredLookBoards && (
              <div className="text-center">Loading...</div>
            )}
            {featuredLookBoards && featuredLookBoards.length === 0 && (
              <div className="mt-2">
                <NoLookBoardsModal />
              </div>
            )}

            {featuredLookBoards && featuredLookBoards.length > 0 && (
              <>
                <div
                  className={clsx(
                    'row mb-3 mt-5',
                    matchesMediaQuery && classes.lookBoardsWrapper
                  )}
                >
                  {featuredLookBoards.map(
                    (lookBoardId) =>
                      lookBoardsData[lookBoardId] && (
                        <div
                          key={lookBoardId}
                          className={clsx(
                            'col-4 px-1 pb-2',
                            matchesMediaQuery && classes.lookBoardWrapper
                          )}
                        >
                          <LookBoardItem
                            lookBoard={lookBoardsData[lookBoardId]}
                            user={{
                              firstName,
                              lastName,
                              userAvatar,
                              displayName,
                            }}
                            products={lookBoardsData[lookBoardId].products}
                            onToggleLike={handleToggleLikeLookBoard}
                            hideLikeVote
                          />
                        </div>
                      )
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsAmbassadorView;
