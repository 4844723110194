import React, { memo, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import takeStyleQuiz from 'assets/img/take-style-quiz.png';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { ADD_PREFERENCES } from 'constants/customEventNames';
import {
  updateGTLProductSearchParamsAction,
  updateSearchParamsAction,
} from 'modules/getTheLook/store/actions';
import customEvent from 'utils/customEvent';
import StyleQuizModal from 'components/modals/StyleQuizModal/StyleQuizModal';
import { updateAmbassadorsSearchParamsAction } from 'modules/getTheLook/ambassadorPage/store/actions';
import SearchInput from 'components/inputs/SearchInput/SearchInput';
import Button from 'components/ui/Button/Button';
import clsx from 'clsx';
import { updateUserSearchAction } from 'modules/getTheLook/userPage/store/actions';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import classes from './TopFilters.module.scss';

const TopFilters = ({
  isAmbassadorPage,
  styleQuizModalOpen,
  setStyleQuizModalOpen,
  handleApplyStyleQuiz,
  setModalVideo,
  isProductView,
}) => {
  const currentUser = useSelector((state) => state.auth.user);
  const quizViewed = useSelector((state) => state.layout.quizViewed);

  const history = useHistory();
  const { pathname } = useLocation();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const ambassadorsSearchParams = useSelector(
    (state) => state.ambassadorsPage.ambassadorsSearchParams
  );
  const searchParams = useSelector((state) => state.getTheLook.searchParams);
  const regularUserSearch = useSelector(
    (state) => state.userPage.usersSearchParams.search
  );

  const isUsersPage = useMemo(
    () => pathname === routesByName.getTheLook.users.index,
    [pathname]
  );

  const handleOpenStyleQuizModal = useCallback(() => {
    if (!quizViewed) history.push(routesByName.styleQuiz);
    setStyleQuizModalOpen(true);
  }, [history, quizViewed, setStyleQuizModalOpen]);

  const handleCloseStyleQuizModal = useCallback(() => {
    setStyleQuizModalOpen(false);
  }, [setStyleQuizModalOpen]);

  const handleAddPreferences = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
    } else {
      history.push(routesByName.account.profile);
      setTimeout(() => {
        customEvent.trigger(ADD_PREFERENCES);
      }, 0);
    }
  }, [currentUser, pathname, history]);

  const handleUpdateSearchParams = useCallback(
    (params) => {
      if (isAmbassadorPage) {
        dispatch(updateAmbassadorsSearchParamsAction({ ...params, offset: 0 }));
      } else if (isUsersPage) {
        dispatch(updateUserSearchAction({ ...params, offset: 0 }));
      } else if (isProductView) {
        dispatch(updateGTLProductSearchParamsAction({ ...params, offset: 0 }));
      } else {
        dispatch(updateSearchParamsAction({ ...params, offset: 0 }));
      }
    },
    [dispatch, isAmbassadorPage, isProductView, isUsersPage]
  );

  const handleChangeSearchQuery = useCallback(
    (search) => {
      handleUpdateSearchParams({ search });
    },
    [handleUpdateSearchParams]
  );

  const handleCrossClick = useCallback(() => {
    handleUpdateSearchParams({ search: '' });
  }, [handleUpdateSearchParams]);

  const inputValue = useMemo(() => {
    if (isAmbassadorPage) return ambassadorsSearchParams.search;
    if (isUsersPage) return regularUserSearch;
    return searchParams.search;
  }, [
    ambassadorsSearchParams.search,
    isAmbassadorPage,
    isUsersPage,
    regularUserSearch,
    searchParams.search,
  ]);

  return (
    <>
      {!matchesMediaQuery && (
        <div className={classes.topFiltersArea}>
          <div
            onClick={handleOpenStyleQuizModal}
            className={classes.styleQuizBlock}
          >
            <p className={classes.quizBtn}>Take the style quiz</p>
            <div className={classes.takeQuizText}>
              Get your <br />
              STYLE <br />
              PERSONA <br />
              <img
                src={takeStyleQuiz}
                className={classes.takeStyleQuizImg}
                alt="takeStyleQuiz"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end flex-fill position-relative">
            <div className={classes.wrapper}>
              <SearchInput
                inputRef={inputRef}
                value={inputValue}
                placeholder="Search by keywords"
                onChange={handleChangeSearchQuery}
                inputClasses={{
                  wrapper: classes.inputWrapper,
                  input: clsx('w-100 d-block', classes.input),
                }}
                onCrossClick={handleCrossClick}
              />
            </div>
            {currentUser && !currentUser.preferences && (
              <Button
                inline
                variant="default"
                size="xs"
                className={classes.addPrefBtn}
                onClick={handleAddPreferences}
              >
                Add Style Preferences
              </Button>
            )}
          </div>
        </div>
      )}
      <StyleQuizModal
        open={styleQuizModalOpen}
        onClose={handleCloseStyleQuizModal}
        onApply={handleApplyStyleQuiz}
        setModalVideo={setModalVideo}
      />
    </>
  );
};

TopFilters.propTypes = {
  isAmbassadorPage: PropTypes.bool,
  styleQuizModalOpen: PropTypes.bool.isRequired,
  setStyleQuizModalOpen: PropTypes.func.isRequired,
  handleApplyStyleQuiz: PropTypes.func.isRequired,
  setModalVideo: PropTypes.func.isRequired,
  isProductView: PropTypes.bool,
};

TopFilters.defaultProps = {
  isAmbassadorPage: false,
  isProductView: false,
};

export default memo(TopFilters);
