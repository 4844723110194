import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Checkbox, FormControlLabel, useMediaQuery } from '@material-ui/core';
import classes from './shapesFilter.module.scss';
import { maxWidthMd } from '../../../../../constants/mediaQueries';
import { shapeByName } from '../../constants';
import { filterModalArrayOf } from '../../../../../modules/requestTheLook/requestDetails/components/FilterModal/propTypes';

const ShapesFilter = ({ options, currentValue, onChange }) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  console.log(options);
  return (
    <div
      className={clsx('', classes.container, {
        'align-items-center': !matchesMediaQuery,
        'align-items-start': matchesMediaQuery,
      })}
    >
      {options.map(({ id: value, name: label }) => (
        <FormControlLabel
          key={label}
          className={clsx(
            'mx-0 align-items-center mb-1',
          )}
          shouldDisableCheckbox={() => false}
          control={
            <div className={classes.shapeContainer}>
              <img
                className={classes.shapeImg}
                src={shapeByName[label]}
                alt={label}
              />
              <div className="d-flex align-items-center">
                <Checkbox
                  id={String(value)}
                  checked={currentValue?.includes(value)}
                  onChange={onChange}
                  size="medium"
                  className={classes.checkbox}
                />
                <p>{label}</p>
              </div>
            </div>
          }
          label=""
        />
      ))}
    </div>
  );
};

ShapesFilter.propTypes = {
  options: filterModalArrayOf.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ShapesFilter;
